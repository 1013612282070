import React, { useContext, useEffect, useState } from "react";
import avatar from "../../../assets/avatar.svg";
// import Chart from 'chart.js/auto';
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UpdateStudentPassword from "./UpdateStudentPassword";

export default function StudentDetails() {
  const { student_id } = useParams();
  const { userAuth } = useContext(auth);
  const navigate = useNavigate();

  const [studentData, setstudentData] = useState(false);
  const [studentCourses, setStudentCourses] = useState(false);
  const [examsScore, setExamsScore] = useState(false);

  const [extraData, setextraData] = useState(false);

  const [updateData, setupdateData] = useState({});

  const [isLoading, setisLoading] = useState(false);

  const [openEditForm, setopenEditForm] = useState(false);
  const [openpopup, setopenpopup] = useState(false);

  const toggleEdit = () => {
    openEditForm ? setopenEditForm(false) : setopenEditForm(true);
  };

  const togglePopup = () => {
    openpopup ? setopenpopup(false) : setopenpopup(true);
  };

  const updateStdentDataEdit = (e) => {
    let data = { ...updateData };
    data[e.target.name] = e.target.value;
    setupdateData(data);
  };

  const sendUpdateData = (e) => {
    e.preventDefault();
    setisLoading(true);
    let data = { ...updateData };
    Object.keys(data).map((item) => {
      if (!data[item]) {
        delete data[item];
      }
      return item;
    });
    if (Object.keys(data).length === 0) {
      setisLoading(false);
      Swal.fire({
        text: " لا يوجد اي تعديل ",
        icon: "warning",
      });
      return;
    }

    axios
      .put(`${config.basURL}dashboard/student-update/${student_id}`, data, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setstudentData(false);
        setisLoading(false);
        getStudentData();
        Swal.fire({
          text: "تم التعديل بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        setisLoading(false);
        Swal.fire({
          text: "حدث خطا برجاء المحاوله مره اخري",
          icon: "error",
        });
        console.log(err);
      });
  };

  const getStudentData = () => {
    axios
      .get(`${config.basURL}dashboard/student-details/${student_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setstudentData(res.data.student);
        setStudentCourses(res.data.courses);
        setExamsScore(res.data.results);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExtraData = () => {
    axios
      .get(`${config.basURL}student/student-extra`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setextraData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteStudent = () => {
    Swal.fire({
      title: `هل انت متاكد من الحذف `,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/student-details/${student_id}`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
            data: {
              student_id: student_id,
            },
          })
          .then((res) => {
            navigate("/dashboard/students");
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              text: " حدث خطاء برجاء المحاوله مره اخري ",
              icon: "error",
            });
          });
      }
    });
  };

  // const exportCsv = () => {
  //   let csv = new CSVMake;
  //   csv.setTableHeader({
  //     name: 'أسم الطالب',
  //     code: 'الكود',
  //     user: ['username', ' رقم الطالب'],
  //     parent_phone: 'رقم ولي الامر',
  //     year: ['name', 'السنه الدراسية'],
  //     place: ['name', 'المكان'],
  //     type_education: ['name', 'نوع التعليم'],
  //   }).setData(studentsData, {
  //     user: 'username',
  //     year: 'name',
  //     place: 'name',
  //     type_education: 'name'
  //   }).exportFile(` بيانات الطلاب - صفحه رقم ( ${currentPage} ) `)
  // }

  useEffect(() => {
    getStudentData();
    // getExamsScore()
    getExtraData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  // const [chartConfig, setChartConfig] = useState({
  //   labels: [
  //     'اسم الكورس - درس رقم 1',
  //     'اسم الكورس - درس رقم 2',
  //     'اسم الكورس - درس رقم 3',
  //     'اسم الكورس - درس رقم 4',
  //     'اسم الكورس - درس رقم 5',
  //   ],
  //   datasets: [
  //     {
  //       label: 'معدل المشاهدات',
  //       data: ['5', '10', '50', 30, 10],
  //       fill: false,
  //       borderColor: '#0278FF',
  //       backgroundColor: '#0278FFCC',
  //       fill: 'origin',
  //       tension: 0.5
  //     }
  //   ]
  // })

  function getHumanDate(d) {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }

  return (
    <>
      <div className="container-fluid student-details-section">
        <h1>الطالب / {studentData && studentData.name}</h1>
        <div className="student-info">
          <div className="section-header">
            <h6>بيانات الطالب</h6>
            <div>
              <button onClick={toggleEdit} className="ms-3">
                <i className="ms-2 fa-solid fa-gear"></i>
                تعديل بيانات الطالب
              </button>
              <button onClick={togglePopup}>
                <i className="ms-2 fa-solid fa-lock"></i>
                تعديل باسورد الطالب
              </button>
              <button onClick={deleteStudent}>
                <i className="ms-2 text-danger fa-regular fa-trash-can"></i>
                حذف الطالب
              </button>
            </div>
          </div>
          <table className="student-table">
            <thead>
              <tr>
                <th></th>
                <th>أسم الطالب</th>
                <th>كود الطالب</th>
                <th>رقم الطالب</th>
                <th>رقم ولي الامر</th>
                <th>السنه الدراسية</th>
                <th>المكان</th>
                <th>الايميل</th>
                <th>نوع الجهاز المسجل بيه</th>
              </tr>
            </thead>
            <tbody>
              {studentData ? (
                <tr>
                  <td className="image">
                    <img src={avatar} alt="" className="w-100 h-100" />
                  </td>
                  <td>{studentData.name}</td>
                  <td>{studentData.code}</td>
                  <td>{studentData.user.username}</td>
                  <td>{studentData.parent_phone}</td>
                  <td>{studentData.year.name}</td>
                  <td>{studentData.place && studentData.place.name}</td>
                  <td>
                    {studentData.user.email ? studentData.user.email : "--"}
                  </td>
                  <td>Phone</td>
                </tr>
              ) : (
                <tr>
                  <td className="w-100" colSpan={9}>
                    <Placeholder />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* edit student data section */}
        <section
          className={`dashboard-student-edit ${openEditForm ? "" : "close"}`}
        >
          <h5 className="pt-4 text-center">
            تعديل بيانات / {studentData && studentData.name}
          </h5>
          <form action="" className="container" onSubmit={sendUpdateData}>
            {studentData && (
              <>
                <input
                  type="text"
                  name="name"
                  placeholder={
                    studentData.name ? studentData.name : "اسم الطالب"
                  }
                  onChange={updateStdentDataEdit}
                />
                <input
                  type="text"
                  name="username"
                  placeholder={
                    studentData.user.username
                      ? studentData.user.username
                      : "رقم هاتف الطالب"
                  }
                  onChange={updateStdentDataEdit}
                />
                <input
                  type="text"
                  name="parent_phone"
                  placeholder={
                    studentData.parent_phone
                      ? studentData.parent_phone
                      : "رقم هاتف ولي الامر"
                  }
                  onChange={updateStdentDataEdit}
                />
                <input
                  type="text"
                  name="email"
                  placeholder={
                    studentData.user.email ? studentData.user.email : "الايميل"
                  }
                  onChange={updateStdentDataEdit}
                />
                <input
                  type="text"
                  name="code"
                  placeholder={
                    studentData.code ? studentData.code : "كود الطالب"
                  }
                  onChange={updateStdentDataEdit}
                />
              </>
            )}
            <select name="year" id="" onChange={updateStdentDataEdit}>
              {extraData && studentData
                ? extraData.years.map((year) => (
                    <option
                      key={year.id}
                      value={year.id}
                      selected={year.id === studentData.year.id ? true : false}
                    >
                      {" "}
                      {year.name}{" "}
                    </option>
                  ))
                : false}
            </select>
            <select name="place" id="" onChange={updateStdentDataEdit}>
              <option> المكان </option>
              {extraData && studentData
                ? extraData.places.map((place) => (
                    <option
                      key={place.id}
                      value={place.id}
                      selected={
                        studentData.place &&
                        (place.id === studentData.place.id ? true : false)
                      }
                    >
                      {" "}
                      {place.name}{" "}
                    </option>
                  ))
                : false}
            </select>
            <select name="type_education" id="" onChange={updateStdentDataEdit}>
              {extraData && studentData
                ? extraData.type_education.map((type) => (
                    <option
                      key={type.id}
                      value={type.id}
                      selected={
                        type.id === studentData.type_education.id ? true : false
                      }
                    >
                      {" "}
                      {type.name}{" "}
                    </option>
                  ))
                : false}
            </select>
            <button>
              {isLoading ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                "تعديل"
              )}
            </button>
          </form>
        </section>

        {/* <div className='student-indicator'>
        <h6>
          معدل مستوى الطالب
        </h6>
        <div className="chart-indicator">
          <Line data={chartConfig} />
        </div>
      </div> */}

        <div className="row courses-homework ">
          <div className="col-lg-5 courses  w-100">
            <h6>الكورسات</h6>
            <div className="content scrolled-y">
              {studentCourses ? (
                studentCourses.length > 0 ? (
                  studentCourses.map((course) => (
                    <div className="course">
                      <div className="image">
                        <img
                          src={`${course.course.cover}`}
                          alt={course.course.name}
                          className="w-100 h-100"
                        />
                      </div>
                      <div>
                        <h6>{course.course.name}</h6>
                        <p className="p-0 m-0 ">{course.course.about}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="m-0 py-3 text-center">
                    {" "}
                    لا يوجد كورسات حتي الان{" "}
                  </p>
                )
              ) : (
                <Placeholder />
              )}
            </div>
          </div>
        </div>
        <div className="student-homeworks">
          <div className="section-header">
            <h6>الواجبات و الدرجات</h6>
            {/* <button className='export-file' onClick={exportCsv}>
            <i className="fa-regular fa-file-excel"></i>
            انشاء ملف Excel
          </button> */}
          </div>
          <table>
            <thead>
              <tr>
                <th>أسم الطالب</th>
                <th>اسم الاختبار</th>
                <th>تاريخ الدخول</th>
                <th>الوقت المستغرق</th>
                <th>نوع الاختبار</th>
                <th>درجه الطالب</th>
                <th>عدد الاسئله</th>
              </tr>
            </thead>
            <tbody>
              {examsScore ? (
                examsScore.length > 0 ? (
                  examsScore.map((exam) => (
                    <tr>
                      <td>{exam.student.name}</td>
                      <td>{exam.exam ? exam.exam.name : exam.quiz.name}</td>
                      <td>{getHumanDate(exam.created)}</td>
                      <td>{exam.time_taken + " دقيقيه"}</td>
                      <td>{exam.exam ? "امتحان" : "واجب"}</td>
                      <td>{exam.degree}</td>
                      <td>{exam.question_answer.length + " سؤال"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    {" "}
                    <td colSpan="7" className="w-100 text-center py-2">
                      {" "}
                      لا يوجد نتائج حتي الان{" "}
                    </td>{" "}
                  </tr>
                )
              ) : (
                <Placeholder />
              )}
            </tbody>
          </table>
        </div>
      </div>

      <UpdateStudentPassword
        open={openpopup}
        student_id={student_id}
        togglePopup={togglePopup}
      />
    </>
  );
}
