import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import { useNavigate, useParams } from "react-router-dom";

export default function CourseSubscriptions() {
  const navigation = useNavigate();

  const { course_id, course_name } = useParams();
  const { userAuth } = useContext(auth);
  const [studentsData, setstudentsData] = useState(false);
  const [studentCount, setstudentCount] = useState(0);

  const getStudentInCourse = () => {
    axios
      .get(`${config.basURL}dashboard/student-in-course/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setstudentsData(res.data);
        setstudentCount(res.data.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStudentInCourse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="course-subscriptions container-fluid py-0 px-3">
        <div className="section-header">
          <h3 className="m-0 p-0">
            الطلاب المشتركين في كورس {course_name} : ( {studentCount} )
          </h3>
        </div>
        <table className="students-info student-table student-table-subscription w-100">
          <thead>
            <tr>
              <th className="text-center"> التسلسل </th>
              <th className="text-center">أسم الطالب</th>
              <th className="text-center">كود الطالب</th>
              <th className="text-center">رقم الطالب</th>
              <th className="text-center">رقم ولي الامر</th>
              <th className="text-center">السنه الدراسية</th>
              <th className="text-center">نوع التعليم</th>
              <th className="text-center">المكان</th>
              <th className="text-center email">الايميل</th>
              <th className="text-center">نوع الجهاز المسجل بيه</th>
            </tr>
          </thead>
          <tbody>
            {studentsData ? (
              studentsData.length > 0 ? (
                studentsData.map((student, index) => (
                  <tr
                    key={index}
                    style={{
                      width: "fit-content !important",
                      minWidth: "100%",
                      marginBottom: "1rem",
                    }}
                  >
                    <td
                      className="image d-flex justify-content-center align-items-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {/* <img src={avatar} alt="" className='w-100 h-100' /> */}
                      {index + 1}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.name}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.code}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.user.username}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.parent_phone}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.year && student.year.name}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.type_education && student.type_education.name}
                    </td>
                    <td
                      className="text-center"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.place ? student.place.name : "--"}
                    </td>
                    <td
                      className="text-center email"
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      {student.user.email.length > 0
                        ? student.user.email
                        : "--"}
                    </td>
                    <td
                      onClick={() =>
                        navigation(`/dashboard/students/${student.id}`)
                      }
                    >
                      Phone
                    </td>
                    {/* <td>
                            <button className='delete' onClick={() => deleteStudent(student.id)}>
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        </td> */}
                  </tr>
                ))
              ) : (
                <tr className="d-flex justify-content-center align-items-center">
                  <td className="w-100 text-center">لا يوجد طلاب</td>
                </tr>
              )
            ) : (
              <tr className="d-flex justify-content-center align-items-center">
                <td className="w-100">
                  <Placeholder />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
