const Placeholder = () => (
  <div className="placeholder-box col-lg-12 py-5">
    <div className="loading-bar">
      <i className="fa-solid fa-circle-notch fa-spin"></i>
      <p className="m-0 p-0">جاري التحميل</p>
    </div>
  </div>
);

export default Placeholder;
