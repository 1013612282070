import React, { useContext, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../App.css';
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";
import { auth } from '../Context/AuthStore';
import config from '../config';
import axios from 'axios';

export default function Layout() {
  const navigate = useNavigate()
  const location = useLocation()
  const {userAuth} = useContext(auth)
  const [openAlert, setopenAlert] = useState(false)
  const [lastNews, setlastNews] = useState('')

  const timeOut = useRef()

  const goToSupport = () => {
    window.location.replace('https://api.whatsapp.com/send?phone=201097779066')
  }

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, [location.pathname])

  const showSwal = (message, icon, redirect = '') => {
    Swal.fire({
      text: message,
      didClose: () => redirect && navigate(redirect),
      icon: icon
    })
  }

  useEffect(() => {

   userAuth && axios.get(`${config.basURL}dashboard/list-news-view` , {
      headers: {
        Authorization: config.ApiKey, 
        auth: `Bearer ${userAuth}`
      }
    }).then(res => {

      let last = localStorage.getItem('lastNotefication')

      if(last == null ||  res.data.count != last ) {
        localStorage.setItem('lastNotefication' , res.data.count)
        setlastNews(res.data.results[0])
        setopenAlert(true)
        timeOut.current =  setTimeout(() => {
          setopenAlert(false)
        } , 3000)
      }

    }).catch(err => {
      console.log(err)
    })
    
  } , [userAuth])

  const closeNew = () => {
    navigate('/news');
    clearTimeout(timeOut.current)
    setopenAlert(false)
  }

  return <>
    <Helmet>
      <title>  مستر محمد صلاح </title>
    </Helmet>
    <div className={`notefication ${openAlert && 'open'}`} onClick={closeNew}>
      {
        lastNews.text
      }
    </div>
    <Header></Header>
    <Outlet context={{ showSwal }}></Outlet>
    <Footer></Footer>
    <div className='contact-support' onClick={goToSupport}>
      <div className="icon">
        <i className="fa-brands fa-whatsapp"></i>
      </div>
      <p> اتواصل مع الدعم </p>
    </div>
  </>
}
