import React, { useEffect, useRef } from 'react'
import Swal from 'sweetalert2';

export default function Videocipher({ avilabile, videoData, firstTime, watchTime, sendWatchTime, intervalID ,setIntervalID,setWatchTime , sendWatchTry}) {

    const player = useRef();
    const iframe = useRef();

    useEffect(() => {
        player.current = window.VdoPlayer.getInstance(iframe.current);
    }, [])

    // record user watch time
    useEffect(() => {

        const playHandler = () => {
            // check if user play video at first time to send request 
            if (firstTime) {
                sendWatchTry()
            }
            const id = setInterval(() => {
                setWatchTime((prevTime) => prevTime + 1)
            }, 1000);
            setIntervalID(id)
        }

        // add send watch when click play
        player.current.video.addEventListener('playing', playHandler);

        return () => {
            player.current.video.removeEventListener('playing', playHandler)
        }

    }, [firstTime, watchTime])

    // pause record user watch time
    useEffect(() => {
        const pauseHandler = () => {
            clearInterval(intervalID)
            sendWatchTime()
        }

        player.current.video.addEventListener('pause', pauseHandler);

        return () => {
            // removing event listener
            player.current.video.removeEventListener('pause', pauseHandler)
        }

    }, [intervalID, watchTime])

    // handle when video is end
    useEffect(() => {
        const handleEndedVideo = () => {
            sendWatchTime();
            clearTimeout(intervalID);
        }
        player.current.video.addEventListener('ended', handleEndedVideo)
        return () => {
            player.current.video.removeEventListener('ended', handleEndedVideo)
        }
    }, [watchTime])

    // stop counter if video not avilabile
    useEffect(() => {
        if (!avilabile) {
            clearInterval(intervalID)
            Swal.fire({
                text: 'انتهت مشاهداتك لهذا الدرس' ,
                icon: 'info'
            })
        }
    }, [avilabile])

    return <>
        {
            avilabile ? <iframe
                ref={iframe}
                src={`https://player.vdocipher.com/v2/?otp=${videoData && videoData.otp}&playbackInfo=${videoData && videoData.playbackInfo}`}
                style={{ 'border': 0 }}
                allow="encrypted-media"
                allowFullScreen
                id='frame'
            ></iframe> : 
            <div className="alert alert-info text-center">انتهت مشاهداتك للدرس</div>
        }
    </>
}
