import { createBrowserRouter } from "react-router-dom";
// import GharabwyHome from "../Views/Theme/GharabwyHome";
import Layout from "../Views/Layout";
import NotFound from "../Views/NotFound";
import Login from "../Views/Auth/Login";
import AuthLayout from "../Views/Auth/AuthLayout";
import Register from "../Views/Auth/Register";
import Courses from "../Views/Courses";
import CoursePreview from "../Views/CoursePreview";
import ProfileLayout from "../Views/Profile/ProfileLayout";
import AuthGourd from "../Gourd/AuthGourd";
import Profile from "../Views/Profile/ProfileSections/Profile";
import UserCourses from "./../Views/Profile/ProfileSections/UserCourses";
import ExamsScore from "../Views/Profile/ProfileSections/ExamsScore";
import GuestGourd from "../Gourd/GuestGourd";
import UserCoursePreview from "../Views/UserCourses/UserCoursePreview";
import UserWatchCourse from "../Views/UserCourses/UserWatchCourse";
import CheckParams from "../Gourd/CheckParams";
import Quiz from "../Views/Quiz/Quiz";
import SecurityAndLogin from "../Views/Profile/ProfileSections/SecurityAndLogin";
import LevelIndicator from "../Views/Profile/ProfileSections/LevelIndicator";
import Notification from "../Views/Profile/ProfileSections/Notification";

// dashboard components
import DashboardLayout from "../Views/Dashboard/DashboardLayout";
import DashboardHome from "../Views/Dashboard/Home/DashboardHome";
import Students from "../Views/Dashboard/Students/Students";
import StudentDetails from "../Views/Dashboard/Students/StudentDetails";
import Categories from "../Views/Dashboard/Categories/Categories";
import DashboardCourses from "../Views/Dashboard/Courses/DashboardCourses";
import Units from "../Views/Dashboard/Units/Units";
import Lessons from "../Views/Dashboard/Lessons/Lessons";
import Videos from "../Views/Dashboard/Videos/Videos";
import Exams from "../Views/Dashboard/Exams/Exams";
import QuestionAnswers from "../Views/Dashboard/Exams/QuestionAnswers";
import SubscriptionsInvoices from "../Views/Dashboard/SubscriptionsInvoices/SubscriptionsInvoices";
import CreateCopon from "../Views/Dashboard/Copon/CreateCopon";
import PrevCopons from "../Views/Dashboard/Copon/PrevCopons";
import Users from "../Views/Dashboard/Users/Users";
import AddUser from "../Views/Dashboard/Users/AddUser";
import DashboardNotififcations from "../Views/Dashboard/Notifications/DashboardNotififcations";
import Attachments from "../Views/Dashboard/Attachments/Attachments";
import CourseSubscreptions from "../Views/Profile/ProfileSections/CourseSubscreptions";
import SubscriptionGaurd from "../Gourd/SubscriptionGaurd";
import HomeWorkScore from "../Views/Profile/ProfileSections/HomeWorkScore";
import StudentAnswers from "../Views/Dashboard/Exams/StudentAnswers";
import CourseSubscription from "../Views/Dashboard/CourseSubscription/CourseSubscription";
import CourseFiles from "../Views/Dashboard/Courses/CourseFiles";
import LessonByCode from "../Views/LessonByCode";
import WatchLessonByCode from "../Views/WatchLessonByCode";
import LessonViews from "../Views/Dashboard/LessonViews/LessonViews";
import Quizes from "../Views/Dashboard/Quizes/Quizes";
import QuizQuestionAnswers from "../Views/Dashboard/Quizes/QuizQuestionAnswers";
import QuizStudentAnswers from "../Views/Dashboard/Quizes/QuizStudentAnswers";
import HaveQuiz from "../Gourd/HaveQuiz";
import QuizBeforeLesson from "../Views/Quiz/QuizeBeforeLesson";
import TahaHome from "../Views/Theme/Taha/TahaHome";
import AllExams from "../Views/Dashboard/AllExams/AllExams";
import AdminGourd from "../Gourd/AdminGourd";
import QuestionUpdate from "../Views/Dashboard/Exams/QuestionUpdate";
import ShowExamAnswers from "../Views/Profile/ProfileSections/ShowExamAnswers";
import DashboardShowStudentAnswers from "../Views/Dashboard/Exams/DashboardShowStudentAnswers";
import StudentAllCourses from "../Views/StudentAllCourses";
import ExamUpdate from "../Views/Dashboard/Exams/ExamUpdate";
import CategoriesSubscriptions from "../Views/Dashboard/Categories/CategoriesSubscriptions";
import CenterCalender from "../Views/CenterCalender";
import CourseSubscriptions from "../Views/Dashboard/Courses/CourseSubscriptions";
import News from "../Views/Dashboard/News/News.jsx";
import NewsStudents from "../Views/Profile/ProfileSections/NewsStudents.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <TahaHome />,
      },
      {
        path: "/Auth",
        element: (
          <GuestGourd>
            <AuthLayout />
          </GuestGourd>
        ),
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
        ],
      },
      {
        path: "/courses",
        element: <Courses />,
      },
      {
        path: "/center-calender",
        element: <CenterCalender />,
      },
      {
        path: "/student-all-courses",
        element: <StudentAllCourses />,
      },
      {
        path: "/lesson-by-code",
        element: <LessonByCode />,
      },
      {
        path: "/watch-lesson-by-code/:code",
        element: <WatchLessonByCode />,
      },
      {
        path: "/course-preview/:course_id",
        element: <CoursePreview />,
      },
      {
        path: "/user-profile",
        element: (
          <AuthGourd>
            <ProfileLayout />
          </AuthGourd>
        ),
        children: [
          {
            index: true,
            element: <Profile />,
          },
          {
            path: "my-courses",
            element: <UserCourses />,
          },
          {
            path: "my-exam-score",
            element: <ExamsScore />,
          },
          {
            path: "security-and-login",
            element: <SecurityAndLogin />,
          },
          {
            path: "level-indicator",
            element: <LevelIndicator />,
          },
          {
            path: "notification",
            element: <Notification />,
          },
          {
            path: "course-subscreptions",
            element: <CourseSubscreptions />,
          },
          {
            path: "my-homework-score",
            element: <HomeWorkScore />,
          },
          {
            path: "show-exam-answers/:row_id/:exam_name",
            element: (
              <AuthGourd>
                <ShowExamAnswers />
              </AuthGourd>
            ),
          },
          {
            path: "news",
            element: <NewsStudents />,
          },
        ],
      },
      {
        path: "news",
        element: <AuthGourd>
          <NewsStudents />
        </AuthGourd>,
      },
      {
        path: "/my-course-preview/:course_id",
        element: (
          <AuthGourd>
            <SubscriptionGaurd>
              <UserCoursePreview />
            </SubscriptionGaurd>
          </AuthGourd>
        ),
      },
      {
        path: "/my-courses/:course_id/watch",
        element: (
          <AuthGourd>
            <SubscriptionGaurd>
              <CheckParams>
                <HaveQuiz>
                  <UserWatchCourse />
                </HaveQuiz>
              </CheckParams>
            </SubscriptionGaurd>
          </AuthGourd>
        ),
      },
      {
        path: "/quiz/:course_id/:exam_id",
        element: (
          <AuthGourd>
            <SubscriptionGaurd>
              <Quiz />
            </SubscriptionGaurd>
          </AuthGourd>
        ),
      },
      {
        path: "/quiz-before-lesson/:course_id/:exam_id",
        element: (
          <AuthGourd>
            <SubscriptionGaurd>
              <QuizBeforeLesson />
            </SubscriptionGaurd>
          </AuthGourd>
        ),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <AuthGourd>
        <AdminGourd>
          <DashboardLayout />
        </AdminGourd>
      </AuthGourd>
    ),
    children: [
      {
        index: true,
        element: <DashboardHome />,
      },
      {
        path: "students",
        element: <Students />,
      },
      {
        path: "students/:student_id",
        element: <StudentDetails />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "courses",
        element: <DashboardCourses />,
      },
      {
        path: "units/:course_id",
        element: <Units />,
      },
      {
        path: "lessons/:unit_id",
        element: <Lessons />,
      },
      {
        path: "videos",
        element: <Videos />,
      },
      {
        path: "exams/:id/:type",
        element: <Exams />,
      },
      {
        path: "questions-answers/:exam_id/:type",
        element: <QuestionAnswers />,
      },
      {
        path: "exam-results/:exam_id",
        element: <StudentAnswers />,
      },
      {
        path: "course-subscriptions",
        element: <CourseSubscription />,
      },
      {
        path: "course-files/:course_id/:type",
        element: <CourseFiles />,
      },
      {
        path: "all-exams",
        element: <AllExams />,
      },
      {
        path: "subscriptions",
        element: <SubscriptionsInvoices />,
      },
      {
        path: "create-copon",
        element: <CreateCopon />,
      },
      {
        path: "prev-copon",
        element: <PrevCopons />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "quizes/:lesson_id",
        element: <Quizes />,
      },
      {
        path: "quiz-questions-answers/:quiz_id",
        element: <QuizQuestionAnswers />,
      },
      {
        path: "quiz-results/:exam_id",
        element: <QuizStudentAnswers />,
      },
      {
        path: "lesson-views",
        element: <LessonViews />,
      },
      {
        path: "exam-question-update/:question_id/:exam_id/:type",
        element: <QuestionUpdate />,
      },
      {
        path: "exam-update/:exam_id/:course_id/:exam_name/:type",
        element: <ExamUpdate />,
      },
      {
        path: "add-user",
        element: <AddUser />,
      },
      {
        path: "show-student-answers/:row_id",
        element: <DashboardShowStudentAnswers />,
      },
      {
        path: "notifications",
        element: <DashboardNotififcations />,
      },
      {
        path: "place-subscriptions/:place_id/:place_name",
        element: <CategoriesSubscriptions />,
      },
      {
        path: "course-subscriptions/:course_id/:course_name",
        element: <CourseSubscriptions />,
      },
      {
        path: "attachments",
        element: <Attachments />,
      },
      {
        path: "news",
        element: <News />,
      },
    ],
  },
]);

export default router;
