import React, { useContext, useState } from 'react'
import axios from 'axios'
import config from '../../../config'
import { auth } from '../../../Context/AuthStore'
import Validator from '../../../Validator'
import Swal from 'sweetalert2'

export default function UpdateStudentPassword({ open, student_id, togglePopup }) {

    const { userAuth } = useContext(auth)

    const [isLoading, setisLoading] = useState(false)

    const closePopup = (e) => {
        if (e.target.classList.contains('update-student-password')) {
            togglePopup()
        }
    }

    const [studentPassword, setstudentPassword] = useState({
        password: '',
        password_confirmation: ''
    })

    const update = (e) => {
        let data = { ...studentPassword }
        data[e.target.name] = e.target.value;
        setstudentPassword(data)
    }

    const sendUpdateStudentPassword = (e) => {
        e.preventDefault()

        setisLoading(true)

        let dataToSend = {...studentPassword}

        let errors = []

        let validation = new Validator();
        validation.setKey('password').setValue(dataToSend.password).required();
        validation.setKey('password_confirmation').setValue(dataToSend.password_confirmation).required().confirm(dataToSend.password);

        if (validation.haveError) {
            setisLoading(false)
            togglePopup()
            Object.keys(validation.getErrors()).map(key => errors.push(validation.getErrors()[key]))
            Swal.fire({
                text: errors.join('\n'),
                icon: 'error'
            })
            return;
        }

        delete dataToSend.password_confirmation;
        dataToSend.student_id = student_id;

        axios.post(`${config.basURL}dashboard/student-rest-password`, dataToSend, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisLoading(false)
            togglePopup()
            Swal.fire({
                text: 'تم تعديل الباسورد بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            setisLoading(false)
            togglePopup()
            console.log(err)
        })

    }

    return <>
        <div className={`create-new-place-popup update-student-password ${open ? 'open' : ''}`} onClick={closePopup} >
            <form className="add-new-place-box" onSubmit={sendUpdateStudentPassword}>
                <input type="password" name="password" onChange={update} placeholder='كلمه المرور'/>
                <input type="password" name="password_confirmation" onChange={update} placeholder='تاكيد كلمه المرور'/>
                <button>
                    {
                        isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل'
                    }
                </button>
            </form>
        </div>
    </>
}
