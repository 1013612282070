import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../../config";
import Validator from "../../../Validator";
import { auth } from "../../../Context/AuthStore";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Placeholder from "../../../Components/Used/Placeholder";
import QuizRow from "../../../Components/Dashboard/Quizes/QuizRow";

export default function Quizes() {
  const { userAuth } = useContext(auth);
  const { lesson_id } = useParams();
  const [allQuizes, setAllQuizes] = useState(false);
  const [createData, setCreateData] = useState({
    name: "",
    is_mandatory: false,
    lesson_quiz: lesson_id,
    success_rate: "",
    time: "",
    pending: null,
  });

  const addCreateData = (e) => {
    let data = { ...createData };
    data[e.target.name] = e.target.value;
    setCreateData(data);
  };

  const updateCheck = () => {
    let check = { ...createData };
    check.is_mandatory
      ? (check.is_mandatory = false)
      : (check.is_mandatory = true);
    setCreateData(check);
  };

  const sendData = (e) => {
    e.preventDefault();
    let validation = new Validator();
    validation.setKey("name").setValue(createData.name).required();
    validation
      .setKey("success_rate")
      .setValue(createData.success_rate)
      .required();
    validation.setKey("time").setValue(createData.time).required();

    if (validation.haveError) {
      Swal.fire({
        text: "جميع الحقول مطلوبه",
        icon: "error",
      });
      return;
    }

    axios
      .post(`${config.basURL}dashboard/create-lesson-quizs`, createData, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        getAllQuizs();
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllQuizs = () => {
    axios
      .get(`${config.basURL}dashboard/get-lesson-quizs/${lesson_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setAllQuizes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteQuiz = (id) => {
    Swal.fire({
      title: `هل انت متاكد من الحذف `,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/quiz-delete/${id}`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
          })
          .then((res) => {
            getAllQuizs();
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    getAllQuizs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-quizes-page">
        <h1>الكويزات</h1>
        <form className="create-new-quiz-form" onSubmit={sendData}>
          <h5 className="py-3">كويز جديد</h5>
          <div className="d-flex justify-content-start align-items-center gap-3">
            <input  
              type="text"
              className=" col-lg-3"
              name="name"
              placeholder="اسم الكويز"
              onChange={addCreateData}
            />
            <input
              type="text"
              className=" col-lg-3"
              name="success_rate"
              placeholder="درجه النجاح"
              onChange={addCreateData}
            />
            <input
              type="text"
              className=" col-lg-3"
              name="time"
              placeholder="مده الكويز"
              onChange={addCreateData}
            />
          </div>
          <div className="d-flex justify-content-start align-items-center mt-3 gap-">
            <select
              name=""
              id=""
              className="col-lg-3 col-md-6 col-12"
              onChange={(e) => {
                if (e.target.value === "active") {
                  setCreateData({ ...createData, pending: true });
                } else {
                  setCreateData({ ...createData, pending: false });
                }
              }}
            >
              <option value="pending">اختر حالة الكويز</option>
              <option value="active">مفعل</option>
              <option value="pending">غير مفعل</option>
            </select>
          </div>
          <div className="py-3 d-flex">
            <input
              type="checkbox"
              name="is_mandatory"
              id="mandatory"
              className="ms-2"
              onChange={updateCheck}
            />
            <label htmlFor="mandatory"> اجباري </label>
          </div>
          <button>اضافه</button>
        </form>
        <div className="all-quizes">
          <h4 className="text-center py-3"> جميع الكويزات </h4>
          <table className="content">
            <thead>
              <tr>
                <th>اسم الكويز</th>
                <th>درجه الكويز</th>
                <th>درجه النجاح</th>
                <th>عدد الاسئله</th>
                <th>المده</th>
                <th>تاريخ الاضافه</th>
                <th>اجباري</th>
                <th>الحالة</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allQuizes ? (
                allQuizes.length > 0 ? (
                  allQuizes.map((quiz) => (
                    <QuizRow
                      refresh={getAllQuizs}
                      quiz={quiz}
                      deleteQuiz={deleteQuiz}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      {" "}
                      لا يوجد كويزات حتي الان في هذا الدرس{" "}
                    </td>
                  </tr>
                )
              ) : (
                <Placeholder />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
