import React from 'react'
import Header from '../../Components/Dashboard/Header'
import { Outlet } from 'react-router-dom'
import '../../Dashboard.css';
import Sidebar from '../../Components/Dashboard/Sidebar';
import { Helmet } from "react-helmet";

export default function DashboardLayout() {
  return <>
    <Helmet>
      <title>  مستر محمد صلاح </title>
    </Helmet>
    <Header />
    <div className='dashboard-layout-content-container'>
      <Sidebar />
      <Outlet />
    </div>
  </>
}
