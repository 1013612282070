import React from 'react'
import '../header/header.css'
import header_img from '../../Components/imges/jjdkff.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
export const Header = ({ dark }) => {
    AOS.init();
    return (
        <header className='mo'>
            <div className='content'>
                <div data-aos-duration='1000' data-aos="fade-left" className="header-right">
                    <h1 className='name'>
                        <span className='start-name'>مستر</span> محمد <span className='salah'>صلاح </span>
                    </h1>
                    <h3>
                        منصة تعليمية متكاملة <br/><span className='math' > بها كل ما   </span>
                    </h3>
                    <h3>    يحتاجه الطالب <span className='secandry'>ليتفوق في الرياضيات</span> </h3>
                </div>
                <div className="header-left">
                    <img className='header_img w-100 h-100' data-aos="fade-right"
                        data-aos-duration='1000' alt="" srcSet={header_img} loading='lazy'/>
                </div>
            </div>
        </header>
    )
}
