import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import Swal from "sweetalert2";
import CourseSubscriptionsRow from "../../../Components/Dashboard/CourseSubscriptionsRow/CourseSubscriptionsRow";
import Validator from "../../../Validator";

export default function CourseSubscription() {
  const [subscriptionData, setSubscriptionData] = useState(false);
  const { userAuth } = useContext(auth);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState(false);
  const [viewsCount, setViewsCount] = useState(0);

  const updateSearchValue = (e) => {
    let data = searchValue;
    data = e.target.value;
    setSearchValue(data);
  };

  const search = (e) => {
    e.preventDefault();
    let validation = new Validator();

    validation.setKey("name").setValue(searchValue).required();

    if (validation.haveError) {
      Swal.fire({
        text: "برجاء ادخال قيمه للبحث",
        icon: "error",
      });
      return;
    }

    axios
      .get(
        `${
          config.basURL
        }dashboard/subscription-overview?page=${currentPage}&code_or_username=${searchValue.trim()}`,
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        setViewsCount(res.data.count);
        setSubscriptionData(res.data.results);
        res.data.next ? setNextPage(true) : setNextPage(false);
      })
      .catch((err) => console.log(err));
  };

  const getSubscriptionData = () => {
    axios
      .get(
        `${config.basURL}dashboard/subscription-overview?page=${currentPage}`,
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        setViewsCount(res.data.count);
        setSubscriptionData(res.data.results);
        res.data.next ? setNextPage(true) : setNextPage(false);
      })
      .catch((err) => console.log(err));
  };

  const toggleActiveCourse = (id, active) => {
    setIsLoading(true);
    axios
      .post(
        `${config.basURL}dashboard/subscription-overview`,
        {
          registration_id: id,
        },
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        getSubscriptionData();
        Swal.fire({
          text: active
            ? "تم الغاء تفعيل الكورس بنجاح"
            : "تم تفعيل الكورس بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSubscriptionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-students-section cousre-subscription-page">
        <div className="head">
          <h6 className="m-0 p-0">اشتراكات الكورسات : ( {viewsCount} )</h6>
        </div>
        <div className="leasson-views-search">
          <form className="search-input" onSubmit={search}>
            <input
              type="text"
              name="code_or_username"
              required
              placeholder="ابحث باسم الطالب / رقم الهاتف / الكود"
              onChange={updateSearchValue}
            />
            <button>بحث</button>
          </form>
        </div>
        <table className="students-info student-table exam-results course-subscriptions student-table-subscription">
          <thead>
            <tr>
              <th className="text-center">  اسم الطالب </th>
              <th className="text-center">اسم الكورس</th>
              <th className="text-center">وقت الاشتراك</th>
              <th className="text-center">سعر الكورس</th>
              <th className="text-center">الصف</th>
              <th className="text-center">التفعيل</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {subscriptionData ? (
              searchResults?.length > 0 ? (
                searchResults?.map((course, index) => (
                  <CourseSubscriptionsRow
                    key={index}
                    data={course}
                    toggle={toggleActiveCourse}
                    loading={isLoading}
                  />
                ))
              ) : subscriptionData?.length > 0 ? (
                subscriptionData?.map((course, index) => (
                  <CourseSubscriptionsRow
                    key={index}
                    data={course}
                    toggle={toggleActiveCourse}
                    loading={isLoading}
                  />
                ))
              ) : (
                <tr className="w-100 d-flex justify-content-center align-items-center">
                  <td className="w-100 text-center" colSpan={6}>
                    {" "}
                    لا يوجد اشتراكات للطلاب{" "}
                  </td>
                </tr>
              )
            ) : (
              <tr className="w-100 d-flex justify-content-center align-items-center">
                <td className="w-100" colSpan={6}>
                  {" "}
                  <Placeholder />{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-btn">
          <button
            onClick={() => setCurrentPage((prev) => prev - 1)}
            className={`prev ${currentPage > 1 ? "" : "disable"}`}
            disabled={currentPage > 1 ? false : true}
          >
            {" "}
            السابق{" "}
          </button>
          <button
            className={`next ${nextPage ? "" : "disable"}`}
            disabled={nextPage ? false : true}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            {" "}
            التالي{" "}
          </button>
        </div>
      </div>
    </>
  );
}
