import React from "react";
import "../advertment/advert.css";
import advertimg from "../imges/advert.png";
import { useNavigate } from "react-router-dom";

export const Advert = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="advert">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="advert-right"
        >
          <img src="" alt="" srcSet={advertimg} loading="lazy" />
        </div>
        <div
          data-aos="fade-right"
          data-aos-duration="1000"
          className="advert-left"
        >
          <h3>منصة مجتمع الرياضيات</h3>
          <h2> لشرح منهج الرياضيات لصفوف المرحلتين الثانوية والاعدادية </h2>
          <span className="mark"></span>
          <p> هل تحتاج مساعدة فى مادة الرياضيات ؟</p>
          <h3>انضم الآن لمنصة الأستاذ محمد صلاح !</h3>
          <button onClick={() => navigate("/auth")} className="shadow__btn">
            انضم الان
          </button>
        </div>
      </div>
      <hr />
    </>
  );
};
