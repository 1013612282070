import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import { useParams } from "react-router-dom";

export default function QuizStudentAnswers() {
  const [examResultsData, setExamResultsData] = useState(false);
  const { userAuth } = useContext(auth);
  const { exam_id } = useParams();

  function getHumanDate(d) {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }

  const getExamResults = () => {
    axios
      .get(`${config.basURL}dashboard/quiz-results/${exam_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setExamResultsData(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getExamResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-students-section ">
        <div className="head">
          <h6 className="m-0 p-0">نتائج الطلاب</h6>
        </div>
        <table className="students-info student-table exam-results">
          <thead>
            <tr>
              <th className="text-center">أسم الطالب</th>
              <th className="text-center">درجه الامتحان</th>
              <th className="text-center">وقت الدخول</th>
              <th className="text-center">الوقت المستغرق في الامتحان</th>
              <th className="text-center">الاجابات الصحيحه</th>
              <th className="text-center">الاجابات الخطاء</th>
            </tr>
          </thead>
          <tbody>
            {examResultsData ? (
              examResultsData.length > 0 ? (
                examResultsData.map((exam, index) => (
                  <tr key={index}>
                    {/* <td className='image'>
                            <img src={avatar} alt="" className='w-100 h-100' />
                        </td> */}
                    <td className="text-center">أسم الطالب</td>
                    <td className="text-center">{exam.degree}</td>
                    <td className="text-center">
                      {getHumanDate(exam.created)}
                    </td>
                    <td className="text-center">{exam.time_taken} دقائق</td>
                    <td className="text-center">الاجابات الصحيحه</td>
                    <td className="text-center">الاجابات الخطاء</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="w-100" colSpan={6}>
                    {" "}
                    لا يوجد نتائج{" "}
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td className="w-100" colSpan={6}>
                  {" "}
                  <Placeholder />{" "}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
