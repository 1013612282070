import React, { useContext, useEffect, useState } from 'react'
import { auth } from '../../../Context/AuthStore'
import config from '../../../config'
import Validator from '../../../Validator'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

export default function CategoryBox({ data, refresh }) {

    const navigation = useNavigate()
    const { userAuth } = useContext(auth);

    const [isUpdateing, setisUpdateing] = useState(false)

    const [updateMood, setupdateMood] = useState(false)
    const [updatePlace, setupdatePlace] = useState({
        name: ''
    })

    const updatePlaceData = (e) => {
        let data = { ...updatePlace }
        data[e.target.name] = e.target.value
        setupdatePlace(data)
    }

    const toggleUpdateMood = () => {
        updateMood ? setupdateMood(false) : setupdateMood(true)
    }


    const sendUpdatePlace = (id) => {
        setisUpdateing(true)

        let validation = new Validator

        validation.setKey('name').setValue(updatePlace.name).required()

        if (validation.haveError) {
            Swal.fire({
                text: 'لا يوجد اي تعديل',
                icon: 'warning'
            })
            return;
        }

        axios.put(`${config.basURL}dashboard/place-update/${id}`, updatePlace, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setisUpdateing(false)
            refresh()
            toggleUpdateMood()
            Swal.fire({
                text: 'تم التعديل',
                icon: 'success'
            })
        }).catch(err => {
            setisUpdateing(false)
            toggleUpdateMood()
            console.log(err)
            Swal.fire({
                text: 'حدث خطاء برجاء المحاوله مره اخري',
                icon: 'error'
            })
        })
    }

    const deletePlace = (id) => {
        let deleteData = { id_place: id }

        Swal.fire({
            title: `هل انت متاكد من الحذف `,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/places-overview`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`
                    },
                    data: deleteData
                }).then((res) => {
                    refresh()
                    Swal.fire({
                        text: 'تمت العمليه بنجاح',
                        icon: 'success'
                    })
                }).catch(err => {
                    console.log(err)
                    Swal.fire({
                        text: 'حدث خطا برجاء المحاوله مره اخري',
                        icon: 'error'
                    })
                })
            }
        })
    }

    return <>
        <div className='category'>
            <div className="top">
                {
                    data.name == 'اونلاين' && data.id == 2 ? false : <div className='icon' onClick={toggleUpdateMood}>
                        <i className="fa-solid fa-eraser"></i>
                    </div>
                }

                {
                    updateMood ? <div className='update-category'>
                        <input type="text" name='name' onChange={updatePlaceData} placeholder={data.name} />
                        <button onClick={() => sendUpdatePlace(data.id)}>
                            {
                                isUpdateing ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل'
                            }  </button>
                    </div> : <h6>
                        {data.name}
                    </h6>
                }

            </div>
            <div className="bottom">
                <div className="current-subscriptions" onClick={() => navigation(`/dashboard/place-subscriptions/${data.id}/${data.name}`)} style={{ cursor: 'pointer' }}>
                    <p>
                        {data.student_count}
                    </p>
                    <p>
                        مشترك حالي
                    </p>
                </div>
                {
                    data.name == 'اونلاين' && data.id == 2 ? false : <div className="delete-icon" onClick={() => deletePlace(data.id)}>
                        <i className="fa-solid fa-trash-can"></i>
                    </div>
                }
            </div>
        </div>
    </>
}
