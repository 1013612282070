import React, { useContext, useEffect, useRef, useState } from "react";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Swal from "sweetalert2";
import axios from "axios";
import Validator from "../../../Validator";

export default function QuizQuestions({ data, refresh, examId, type }) {
  const { userAuth } = useContext(auth);
  const [openCollaps, setOpenCollaps] = useState(false);
  const [newAnswers, setNewAnswers] = useState({ correct: false });
  const [openAddNewAnswer, setopenAddNewAnswer] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  var refdiv = useRef(null);
  var rte = useRef(null);

  const openCollapsFunc = () => {
    openCollaps ? setOpenCollaps(false) : setOpenCollaps(true);
  };

  const togglePopup = () => {
    openAddNewAnswer ? setopenAddNewAnswer(false) : setopenAddNewAnswer(true);
  };

  const closePopup = (e) => {
    if (e.target.classList.contains("add-new-popup")) {
      setopenAddNewAnswer(false);
    }
  };

  const addNewAnswer = (e) => {
    e.preventDefault();
    setisLoading(true);
    // copy form new answer data
    let dataNewAnswer = { ...newAnswers };
    dataNewAnswer["text"] = rte.current.getHTMLCode();

    // set answer number
    let answerNo = `answer-${data.answer_set.length + 1}`;
    let ans = {
      answers_set: {},
    };

    ans.answers_set[answerNo] = dataNewAnswer;

    // validation phase
    let valid = new Validator();
    valid.setKey("text").setValue(dataNewAnswer.text).required();
    if (valid.haveError) {
      setisLoading(false);
      togglePopup();
      Swal.fire({
        text: "جميع الحقول مطلوبه",
        icon: "warning",
      });
      return;
    }

    axios
      .post(`${config.basURL}dashboard/quiz-answer/${data.id}`, ans, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setisLoading(false);
        refresh();
        togglePopup();
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
        togglePopup();
      });
  };

  const updateNewAnswers = (e) => {
    let data = { ...newAnswers };
    data.correct ? (data.correct = false) : (data.correct = false);
    setNewAnswers(data);
  };

  const deleteLesson = (id) => {
    Swal.fire({
      title: `هل انت متاكد من حذف السؤال : ${data.text}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/quiz-questions/${examId}`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
            data: { id_question: id },
          })
          .then((res) => {
            refresh();
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          });
      }
    });
  };

  const deleteAnswer = (id) => {
    Swal.fire({
      title: `هل انت متاكد من حذف الاجابه `,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/quiz-answer/${data.id}`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
            data: { id_answer: id },
          })
          .then((res) => {
            refresh();
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          });
      }
    });
  };

  useEffect(() => {
    rte.current = new window.RichTextEditor(refdiv.current);
    rte.current.setHTMLCode("");
  }, []);

  return (
    <>
      <div className="question-row">
        <div className="right">
          <p dangerouslySetInnerHTML={{ __html: data.text }} />
          <p className="question-grade"> درجه السؤال : {data.grade} </p>
          <p className="question-answers">
            {" "}
            عدد الاجابات : {data.answer_set.length}{" "}
          </p>
        </div>
        <div className="left">
          <button className="answers" onClick={openCollapsFunc}>
            {" "}
            الاجابات{" "}
          </button>
          {/* <button className='setting' >
                    <i className="fa-solid fa-gear"></i>
                </button> */}
          <button className="delete" onClick={() => deleteLesson(data.id)}>
            <i className="fa-regular fa-trash-can"></i>
          </button>
        </div>
      </div>
      {/* answers */}
      <div className={`answers-collaps ${openCollaps ? "open" : ""}`}>
        <div className="collaps-header">
          <p> اجابات السؤال</p>
          <button onClick={togglePopup}> اضافه اجابه </button>
        </div>
        <div className="answers">
          {data.answer_set.length > 0 ? (
            data.answer_set.map((answer) => (
              <div className="answer" key={answer.id}>
                <p dangerouslySetInnerHTML={{ __html: answer.text }} />
                <button
                  className="delete"
                  onClick={() => deleteAnswer(answer.id)}
                >
                  <i className="fa-regular fa-trash-can"></i>
                </button>
              </div>
            ))
          ) : (
            <p className="text-center w-100 empty">
              {" "}
              لا توجد اجابات لهذا السؤال{" "}
            </p>
          )}
        </div>
      </div>

      {/* add new answer popup */}
      <div
        className={`add-new-popup ${openAddNewAnswer ? "open" : ""}`}
        onClick={closePopup}
      >
        <form className="new-box" onSubmit={addNewAnswer}>
          <div ref={refdiv} className="text"></div>
          <div className="check">
            <input
              type="checkbox"
              name="correct"
              id="correct-answer"
              value={true}
              onChange={updateNewAnswers}
            />
            <label htmlFor="correct-answer"> الاجابه الصحيحه </label>
          </div>
          <button onClick={addNewAnswer}>
            {" "}
            {isLoading ? (
              <i className="fa-solid fa-circle-notch fa-spin"></i>
            ) : (
              "اضافه"
            )}{" "}
          </button>
        </form>
      </div>
    </>
  );
}
