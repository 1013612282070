import React, { useState } from "react";
import { Line } from "react-chartjs-2";

export default function LevelIndicator() {
  // eslint-disable-next-line no-unused-vars
  const [chartConfig, setChartConfig] = useState({
    labels: [
      "اسم الكورس - درس رقم 1",
      "اسم الكورس - درس رقم 2",
      "اسم الكورس - درس رقم 3",
      "اسم الكورس - درس رقم 4",
      "اسم الكورس - درس رقم 5",
      "اسم الكورس - درس رقم 6",
      "اسم الكورس - درس رقم 7",
      "اسم الكورس - درس رقم 8",
      "اسم الكورس - درس رقم 9",
      "اسم الكورس - درس رقم 10",
      "اسم الكورس - درس رقم 10",
      "اسم الكورس - درس رقم 10",
      "اسم الكورس - درس رقم 11",
    ],
    datasets: [
      {
        label: "معدل المشاهدات",
        data: ["5", "10", "50", 30, 10, 50, 60, 100, 80, 90, 100],
        borderColor: "#00BD99",
        backgroundColor: "#00BD99CC",
        fill: "origin",
        tension: 0.5,
      },
    ],
  });

  return (
    <>
      <div className="level-indicator-page container">
        <div className="row gap-1">
          <div className="chart-container col-lg-7">
            <Line data={chartConfig} />
          </div>
          <div className="filer-box col-lg-4">
            <h5 className="fw-bold mb-4">اضافة</h5>
            <div className="course-name mb-4">
              <h6>أختر أسم الكورس</h6>
              <select name="course-name" className="w-100">
                <option></option>
                <option> أسم الكورس</option>
                <option> أسم الكورس</option>
                <option> أسم الكورس</option>
                <option> أسم الكورس</option>
              </select>
            </div>
            <div className="lesson-name">
              <h6>أختر أسم الدرس</h6>
              <select name="lesson-name" className="w-100">
                <option></option>
                <option> أسم الدرس</option>
                <option> أسم الدرس</option>
                <option> أسم الدرس</option>
                <option> أسم الدرس</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
