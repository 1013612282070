import React from 'react'

export default function Invoice() {
    return <>
        <div className='invoice-row-box'>
            <div className="right">
                <div className="student-name">
                    <h6>
                        أسم الطالب
                    </h6>
                    <p>
                        Acount.Name@gmail.com
                    </p>
                </div>
                <div className="invoice-date">
                    <h6>
                        تاريخ الفاتورة
                    </h6>
                    <p>
                        03 / 4/ 2024
                    </p>
                </div>
                <div className="pay-date">
                    <h6>
                        تاريخ الدفع
                    </h6>
                    <p>
                        03 / 5/ 2024
                    </p>
                </div>
                <div className="course-name">
                    <h6>
                        الكورس
                    </h6>
                    <p>
                        أسم الكورس
                    </p>
                </div>
                <div className="price">
                    <h6>
                        المبلغ
                    </h6>
                    <p>
                        350ج
                    </p>
                </div>
                <div className='payment-methoud'>
                    <h6>
                        طريقة الدفع
                    </h6>
                    <p>
                        Phone Cach
                    </p>
                </div>
            </div>
            <div className="left">
                <div className='discount-code'>
                    <h6>
                        كود خصم 20%
                    </h6>
                    <p>
                        lsdol937
                    </p>
                </div>
                <button>
                    <i className="fa-solid fa-chevron-up"></i>
                </button>
            </div>
        </div>
    </>
}
