import axios from "axios";
import React, { useContext } from "react";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Swal from "sweetalert2";

export default function NewsBox({ data, idx, refresh }) {
  const { userAuth } = useContext(auth);

  const getHumanDate = (d) => {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  };

  const deleteNews = () => {
    Swal.fire({
      title: `هل انت متاكد من حذف الخبر : ${data?.text?.slice(0, 20)}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/delete-news-view/${data?.id}`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
            data: {
              id_unit: data.id,
            },
          })
          .then((res) => {
            refresh();
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <div className="unit-course-box news-course-box w-100">
      <div className="lessons">
        <p>{idx}</p>
      </div>
      <p className="title">{data.text}</p>
      <div className="date-setting">
        <div className="date">
          <p className="m-0">التاريخ</p>
          <p className="m-0">{getHumanDate(data.created)}</p>
        </div>
      </div>
      <div className="date-setting">
        <div className="controll-btns">
          <button className="delete delete-btn" onClick={deleteNews}>
            <i className="fa-regular fa-trash-can delete-icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
