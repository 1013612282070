import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";

export default function ShowExamAnswers() {
  const { row_id, exam_name } = useParams();
  const { userAuth } = useContext(auth);
  const [studentAnswers, setstudentAnswers] = useState(false);

  const getAnswers = () => {
    axios
      .get(`${config.basURL}exam/exam-questions-result/${row_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setstudentAnswers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container show-student-answers">
        <div className="answers">
          <h2>اجابات امتحان : {exam_name}</h2>
          {studentAnswers ? (
            studentAnswers.length > 0 ? (
              studentAnswers.map((answer, index) => (
                <div className="answer-box" key={answer.id}>
                  <h3 className="text-center py-3">
                    {" "}
                    السؤال رقم : {index + 1}{" "}
                  </h3>
                  <h5
                    className="question-title"
                    dangerouslySetInnerHTML={{ __html: answer.question_text }}
                  ></h5>
                  <div className="title">
                    <p> اجابه الطالب </p>
                    <p> الاجابه الصحيحه </p>
                  </div>
                  <ul className="student-answer">
                    <li
                      className={`${answer.grade > 0 ? "right" : "wrong"}`}
                      dangerouslySetInnerHTML={{ __html: answer.answer_text }}
                    ></li>
                    <li
                      dangerouslySetInnerHTML={{
                        __html: answer.correct_answer_text,
                      }}
                    ></li>
                  </ul>
                </div>
              ))
            ) : (
              <p className="text-center py-2 w-100"> لا يوجد اجابات </p>
            )
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </>
  );
}
