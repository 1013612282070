import React from 'react'
import './Tahastyle.css';
import '../Taha/../../../Components/header/header.css'
import { Header } from '../../../Components/header/Header';
import { Properties } from '../../../Components/properties/Properties';
import { Advert } from '../../../Components/advertment/Advert';
import { Department } from '../../../Components/departments/Department';

export default function TahaHome() {

    return <>
        <Header/>
        <section className='features mt-5 py-5'>
        {/* start best plateforms */}
            <Advert/>
            <Properties/>
        </section>
        {/* // start courses section */}
        <Department/>
    </>
}
