import React, { Fragment, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function CourseUniteBox(props) {
  // const circleElement = useRef();
  const lessons = useRef();
  const { course_id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [lessonID, setLessonID] = useState(0);
  const [openCollapse, setopenCollapse] = useState(false);

  const openCollapseFunc = () => {
    openCollapse ? setopenCollapse(false) : setopenCollapse(true);
  };

  // const selectAction = (e) => {
  //   // remove select class from all elements
  //   let elements = circleElement.current.children;
  //   for (let i = 0; i < elements.length; i++) {
  //     elements[i].classList.remove("selected");
  //   }
  //   // add selected class to selected element
  //   e.target.className === "action-icon"
  //     ? e.target.classList.add("selected")
  //     : e.target.parentElement.classList.add("selected");
  // };

  const selectLesson = (e) => {
    setLessonID(e.target.getAttribute("data-id"));
    // remove select class from all elements
    let elements = lessons.current.children;
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("selected");
    }
    // add selected class to selected element
    e.target.classList.add("selected");
  };

  return (
    <>
      <div className="course-unit-box col-lg-12 p-0">
        <h5 className="title w-100" onClick={openCollapseFunc}>
          {props.data && props.data.name}
          <i
            className={`fa-solid ${
              openCollapse ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </h5>
        <ul
          className={`lessons w-100 ${openCollapse ? "open" : ""}`}
          ref={lessons}
        >
          {props.data &&
            props.data.unit_lessons.map((lesson) => (
              <Fragment key={lesson.id}>
                <li key={lesson.id} data-id={lesson.id} onClick={selectLesson}>
                  <Link
                    className="w-100 h-100 d-block"
                    to={`/my-courses/${course_id}/watch?unit=${props.data.id}&lesson=${lesson.id}`}
                  >
                    <i className=" ms-2 fa-solid fa-display"></i>
                    {lesson.name}
                  </Link>
                </li>
                {lesson.exam_set.length > 0 &&
                  lesson.exam_set.map((quiz) => (
                    <li key={quiz.id}>
                      <Link
                        className="w-100 h-100 d-block"
                        to={`/quiz/${course_id}/${quiz.id}`}
                      >
                        <i className="ms-2 fa-solid fa-clipboard-question"></i>
                        {quiz.name} ( اختبار )
                      </Link>
                    </li>
                  ))}
                {lesson.lesson_file.length > 0 &&
                  lesson.lesson_file.map((file) => (
                    <li key={file.id}>
                      <a className="w-100 h-100 d-block" href={`${file.file}`}>
                        <i className="ms-2 fa-regular fa-folder-open"></i>
                        {decodeURIComponent(
                          file.file.split("/")[file.file.split("/").length - 1]
                        )}
                      </a>
                    </li>
                  ))}
                {lesson.lesson_quiz.length > 0 &&
                  lesson.lesson_quiz.map((quiz) => (
                    <li key={quiz.id}>
                      <Link
                        className="w-100 h-100 d-block"
                        to={`/quiz-before-lesson/${course_id}/${quiz.id}`}
                      >
                        <i className="ms-2 fa-regular fa-circle-question"></i>
                        {quiz.name} ( واجب )
                      </Link>
                    </li>
                  ))}
              </Fragment>
            ))}
        </ul>
        {/* <Link to={`/my-courses/${course_id}/watch?unit=1&lesson=${lessonID}`} className="start">
          البدء
        </Link> */}
      </div>
    </>
  );
}
