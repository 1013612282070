
const { createContext, useState, useEffect } = require("react");

export let auth = createContext({});

export default function AuthContextProvider(props) {

    const [userAuth, setUserAuth] = useState(null);

    // logout function
    const logout = () => {
        setUserAuth(null);
        localStorage.removeItem('userToken')
    }

    useEffect(() => {
        // handle reload if user is auth
        let userToken = localStorage.getItem('userToken');
        if (userToken) {
            setUserAuth(userToken)
        }
    }, [])

    return <auth.Provider value={{ userAuth, setUserAuth, logout}} >
        {props.children}
    </auth.Provider>
}