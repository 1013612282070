import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './../../Components/Profile/Sidebar';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import { auth } from '../../Context/AuthStore';

export default function ProfileLayout() {

  const [studentYear, setStudentYear] = useState(1)
  const {userAuth} = useContext(auth)

  const getStudentYear = () => {
    axios.get(`${config.basURL}student/student-profile` , {
      headers: {
        'Authorization': config.ApiKey ,
        'auth' : `Bearer ${userAuth}`
      }
    }).then(res => {
      setStudentYear(res.data.year.id)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getStudentYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [])

  return <>
    <div className='profile-layout ps-3'>
        <Sidebar year={studentYear}/>
        <Outlet />
    </div>
  </>
}
