import incom from "../../../assets/incom.svg";
import avatar from "../../../assets/avatar.svg";
import Invoice from "../../../Components/Dashboard/Invoices/Invoice";

export default function SubscriptionsInvoices() {
  return (
    <>
      <div className="container-fluid dashboard-subscriptions-invoices">
        <h1>الأشتراكات و الفواتير</h1>
        <div className="totla-incom-subscription-count">
          <div className="total-incom">
            <div className="total">
              <h6>اجمالي الارباح</h6>
              <p className="m-0">
                <span>57,347</span> جنيه
              </p>
            </div>
            <div className="image">
              <img src={incom} alt="" className="w-100 h-100" />
            </div>
          </div>
          <div className="subscription-count">
            <div className="count">
              <h6>عدد المشتركين</h6>
              <p className="m-0 text-white">3598</p>
            </div>
            <div className="image">
              <img src={avatar} alt="" className="w-100 h-100" />
            </div>
          </div>
        </div>
        <h2 className="page-title">الفواتير</h2>
        <div className="filter-invoices">
          <div className="filter">
            <button>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
            <ul className="invoice-type">
              <li>Phone cach</li>
              <li>Cach</li>
              <li>Visa</li>
            </ul>
          </div>
          <div className="date-filter">
            <div className="from">
              <label htmlFor="from">من تاريخ</label>
              <input type="date" id="from" />
            </div>
            <div className="separate"></div>
            <div className="to">
              <label htmlFor="to">الى تاريخ</label>
              <input type="date" id="to" />
            </div>
          </div>
        </div>
        <div className="invoices">
          <div className="add-new-invoice">
            <h6>اضافة فاتورة يدوية</h6>
            <div className="new-invoice">
              <div className="student-name">
                <label htmlFor="name">أسم الطالب</label>
                <input type="text" id="name" />
              </div>
              <div className="course-name">
                <label htmlFor="">أسم الكورس</label>
                <select name="" id="">
                  <option value=""></option>
                </select>
              </div>
              <div className="price">
                <label htmlFor="price">المبلغ</label>
                <input type="text" id="price" />
              </div>
              <div className="discount-copon">
                <label htmlFor="discount">كوبون الخصم ( أختياري )</label>
                <input type="text" id="discount" />
              </div>
              <button className="save-data">حفظ</button>
            </div>
          </div>
          <div className="invoice-container">
            <Invoice />
          </div>
        </div>
      </div>
    </>
  );
}
