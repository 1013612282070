import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import Placeholder from "../../../Components/Used/Placeholder";
import NewsBoxStudents from "../../../Components/Profile/NewsBoxStudents";
import SectionContainer from "../../../Components/Dashboard/Used/SectionContainer";
import { auth } from "../../../Context/AuthStore";

const NewsStudents = () => {

  const {userAuth} = useContext(auth)

  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getNewsData = () => {
    setIsLoading(true);
    axios
      .get(`${config.basURL}dashboard/list-news-view`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`
        },
      })
      .then((res) => {
        setIsLoading(false);
        setNewsData(res.data.results.reverse());
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getNewsData();
  }, [userAuth]);

  return (
    <div
      className="container-fluid dashboard-units-page mb-3"
      style={{
        paddingTop:
          window.location.pathname === "/news"
            ? isMobile
              ? "3rem"
              : "7rem"
            : "0",
        marginBottom: "9rem",
      }}
    >
      <SectionContainer sectionTitle="أخر الأخبار">
        {isLoading ? (
          <Placeholder />
        ) : newsData.length > 0 ? (
          newsData.map((news) => <NewsBoxStudents key={news.id} data={news} />)
        ) : (
          <h5 className="text-center mt-3">لا يوجد أخبار حتي الآن</h5>
        )}
      </SectionContainer>
    </div>
  );
};

export default NewsStudents;
