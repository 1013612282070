import React, { useState } from 'react'

export default function CourseSubscriptionsRow({ data, toggle, loading }) {

    const [collabs, setCollabs] = useState(true)

    function getHumanDate(d) {
        const date = new Date(d);
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('ar', options);
    }

    const openCollabse = () => {
        collabs ? setCollabs(false) : setCollabs(true)
    }

    return <>
        <tr>
            <td className='text-center'>
                {data.student.name}
            </td>
            <td className='text-center'>
                {data.course.name}
            </td>
            <td className='text-center'>
                {getHumanDate(data.created)}
            </td>
            <td className='text-center'>
                {data.course.price} ج
            </td>
            <td className='text-center'>
                {data.course.year.name}
            </td>
            <td className='text-center'>
                <button onClick={() => toggle(data.id, data.active)} className={`activation ${data.active ? 'active' : ''}`}> {loading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : (data.active ? 'تم التفعيل' : 'غير مفعل')} </button>
            </td>
            <td onClick={openCollabse}>
                <i className="fa-solid fa-chevron-down"></i>
            </td>
        </tr>
        <tr className={`collabs ${collabs ? 'close' : ''}`}>
            <td>
                رقم هاتف الطالب : {data.student.user.username}
            </td>
            <td>
                طريقه التسجيل : {data.student.place.name}
            </td>
            <td>
                نوع التعليم : {data.student.type_education.name}
            </td>
            <td>
                المحافظه : {data.student.governorate}
            </td>
            <td>
                كود الطالب : {data.student.code && 'لا يوجد'}
            </td>
        </tr>

    </>
}
