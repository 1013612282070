import React, { useEffect, useState } from "react";
import config from "../../../config";
import axios from "axios";
import government from "../../../governorates.json";

export default function ThirdStep({
  updateUserData,
  clearInputData,
  registerData,
  show,
  errors,
}) {
  const [data, setData] = useState(false);

  const getData = () => {
    axios
      .get(`${config.basURL}student/student-extra`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <form
        className={`container first-step-form form-steps ${
          show ? "" : "d-none"
        }`}
      >
        <div className="row gap-2">
          <div className="col-lg-5">
            <select
              onChange={updateUserData}
              name="governorate"
              className={`w-100 ${errors["governorate"] ? "error" : ""}`}
            >
              <option>المحافظة</option>
              {government[0].data.map((city) => (
                <option value={city.governorate_name_ar}>
                  {city.governorate_name_ar}
                </option>
              ))}
            </select>
            <ul className={errors["governorate"] ? "" : "d-none"}>
              {errors["governorate"] &&
                Object.values(errors["governorate"]).map((error) => (
                  <li className="text-danger"> {error} </li>
                ))}
            </ul>
          </div>
          <div className="col-lg-5">
            <select
              onChange={updateUserData}
              name="year"
              className={`w-100 ${errors["year"] ? "error" : ""}`}
            >
              <option>الصف الدراسي</option>
              {
                data &&
                  data.years
                    .filter((year) => year.name !== "الكل")
                    .map((place) => (
                      <option value={place.id}>{place.name}</option>
                    ))
                // data && data.years.map(place => <option value={place.id}>{place.name}</option>)
              }
            </select>
            <ul className={errors["year"] ? "" : "d-none"}>
              {errors["year"] &&
                Object.values(errors["year"]).map((error) => (
                  <li className="text-danger"> {error} </li>
                ))}
            </ul>
          </div>
          {/* <div className="col-lg-5">
                    <select onChange={updateUserData} name='place' className={`w-100 ${errors['place'] ? 'error' : ''}`}>
                        <option>
                            طريقة التسجيل
                        </option>
                        {
                            data && data.places.map(place => <option value={place.id}>{place.name}</option>)
                        }
                    </select>
                    
                </div> */}
          <div className="col-lg-10">
            <select
              onChange={updateUserData}
              name="type_education"
              className={`w-100 ${errors["type_education"] ? "error" : ""}`}
            >
              <option>نوع التعليم</option>
              {data &&
                data.type_education.map((place) => (
                  <option value={place.id}>{place.name}</option>
                ))}
            </select>
            <ul className={errors["type_education"] ? "" : "d-none"}>
              {errors["type_education"] &&
                Object.values(errors["type_education"]).map((error) => (
                  <li className="text-danger"> {error} </li>
                ))}
            </ul>
          </div>
          {/* <div className='input-style col-lg-10'>
                    <input onChange={updateUserData} name='code' type='text' placeholder='كود السنتر' value={registerData['code']} />
                    <div className={`clear-input ${registerData['code'] === '' ? 'd-none' : ''}`} onClick={() => clearInputData('code')}>
                        <i className="fa-solid fa-circle-xmark"></i>
                    </div>
                </div> */}
        </div>
      </form>
    </>
  );
}
