import React, { useContext, useEffect, useState } from "react";
import CourseContentCollapse from "./../Components/Courses/CourseContentCollapse";
import { useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { auth } from "../Context/AuthStore";

export default function CoursePreview() {
  const { course_id } = useParams();
  const { userAuth } = useContext(auth);
  const [courseData, setCourseData] = useState({});
  const [courseUnits, setCourseUnits] = useState(false);
  const { showSwal } = useOutletContext();

  const getCourseData = () => {
    axios
      .get(`${config.basURL}course/course-details/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        // console.log(res.data)
        let course = { ...courseData };
        course = res.data;
        setCourseData(course);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourseUnits = () => {
    axios
      .get(`${config.basURL}course/course-units/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setCourseUnits(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const subscripe = () => {
    if (userAuth === null) {
      showSwal(
        "لا يمكن الاشتراك في الكورس بدون تسجيل الدخول",
        "error",
        "/auth"
      );
    } else {
      axios
        .post(
          `${config.basURL}subscription/register-course`,
          {
            course: courseData.id,
          },
          {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
          }
        )
        .then((res) => {
          if (res.data.active) {
            showSwal(
              "تم الاشتراك في الكورس بنجاح",
              "success",
              "/user-profile/course-subscreptions"
            );
          } else {
            showSwal("لقد قمت بالاشتراك في هذا الكورس من قبل", "info");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function getHumanDate(d) {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }

  useEffect(() => {
    getCourseData();
    getCourseUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="course-details mb-5">
        <div className="details-box">
          <div className="course-image mb-2">
            <img src={courseData.cover} alt="" className="w-100 h-100" />
          </div>
          <div className="course-title-price">
            <div className="course-title">
              <h5 className="fw-bold">{courseData.name}</h5>
              <p className="m-0 p-0">{courseData.about}</p>
            </div>
            <p className="price m-0 p-0">
              {!courseData.free ? (
                <>
                  <span>{courseData.price}</span>جـ
                </>
              ) : (
                "مجاني"
              )}
            </p>
          </div>
          <div className="time-content-details py-3">
            <div className="time-details">
              <div className="time">
                <i className="fa-regular fa-clock"></i>
                <p className="m-0 p-0">{getHumanDate(courseData.created)}</p>
              </div>
            </div>
            <div className="content-details py-3">
              <div className="details">
                <div className="right">
                  <div className="icon">
                    <i className="fa-solid fa-circle-exclamation"></i>
                  </div>
                  <p className="m-0 p-0">المحتوى</p>
                </div>
                <p className="left m-0 p-0">{courseData.time / 60} ساعة</p>
              </div>
              <div className="details">
                <div className="right">
                  <div className="icon">
                    <i className="fa-solid fa-graduation-cap"></i>
                  </div>
                  <p className="m-0 p-0">الطلاب</p>
                </div>
                <p className="left m-0 p-0">
                  {courseData.student_in_course_count} طالب
                </p>
              </div>
              <div className="details">
                <div className="right">
                  <div className="icon">
                    <i className="fa-solid fa-book"></i>
                  </div>
                  <p className="m-0 p-0">الدروس</p>
                </div>
                <p className="left m-0 p-0">{courseData.lessons_count} دروس</p>
              </div>
            </div>
          </div>
          <div className="p-2 btns">
            <div className="subscribe-now fw-bold" onClick={subscripe}>
              أشترك الأن
            </div>
            {/* <div className='add-to-cart fw-bold'>
                        أضف للسلة
                    </div> */}
          </div>
        </div>
      </div>
      <div className="container course-content py-4">
        <h5 className="py-3">محتوى الكورس</h5>
        <div className="row">
          {courseUnits &&
            courseUnits.map((unit) => (
              <CourseContentCollapse key={unit.id} data={unit} />
            ))}
        </div>
        {courseData.course_file && (
          <>
            <h5 className="py-3 mt-4"> فايلات </h5>
            <div className="course-files">
              {courseData.course_file.map((file) => (
                <div className="file" key={file.id}>
                  <div className="icon">
                    <i className="fa-solid fa-file"></i>
                  </div>
                  <p className="m-0">
                    {decodeURIComponent(
                      file.file.split("/")[file.file.split("/").length - 1]
                    )}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
