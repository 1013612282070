import React, { useEffect, useRef, useState } from 'react'

export default function Counter({ time, end , takenTime}) {

    let [minutes, setMinutes] = useState(time)
    let [seconds, setSeconds] = useState(0)

    useEffect(() => {

        // window.addEventListener('load' , () => {
        //     if(localStorage.getItem('quiz-seconds') && localStorage.getItem('quiz-minutes')){
        //         setMinutes(localStorage.getItem('quiz-minutes'))
        //         setSeconds(localStorage.getItem('quiz-seconds'))
        //     }
        // })

        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    // submit action
                    // send amswers when counter is finish
                    end('للاسف انتهي وقت الامتحان', 'error')
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
            takenTime(() => time - minutes)
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });
    return <>
        <div className='time'>
            <div className='minute'>{minutes < 10 ? `0${minutes}` : minutes}</div>
            :
            <div className='secund'>{seconds < 10 ? `0${seconds}` : seconds}</div>
        </div>
    </>
}
