import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import Validator from "../../../Validator";
import Swal from "sweetalert2";
import NewsBox from "../../../Components/Dashboard/News/NewsBox";

export default function News() {
  const { userAuth } = useContext(auth);
  const [newsData, setNewsData] = useState([]);
  const [createNew, setCreateNew] = useState({ text: "" , year: '' });
  const [openPopup, setOpenPopup] = useState(false);
  const [currentNews, setCurrentNews] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [years, setyears] = useState(false)

  const [updateData, setUpdateData] = useState({});

  const getExtraData = () => {
    axios
      .get(`${config.basURL}student/student-extra`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setyears(res.data.years);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addData = (e) => {
    setCreateNew({
      ...createNew,
      [e.target.name]: e.target.value,
    });
  };

  const updateDataFunc = (e) => {
    setUpdateData({
      ...updateData,
      name: e.target.value,
    });
  };

  const validation = () => {
    let validate = new Validator();
    validate.setKey("text").setValue(createNew.text).required();
    return validate;
  };

  const closePopup = (e) => {
    if (e.target.classList.contains("update-unite-popup")) {
      setOpenPopup(false);
    }
  };

  const openPopupFunc = (data) => {
    setOpenPopup(true);
    setCurrentNews(data);
  };

  const getAllCourses = () => {
    axios
      .get(`${config.basURL}dashboard/list-news-view`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setNewsData(res.data.results.reverse());
        console.log(res.data)
      })
      .catch((err) => console.log(err));
  };

  const createNews = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let valid = validation();
    let displayError;
    if (valid.haveError) {
      for (let key in valid?.getErrors()) {
        displayError += valid.getErrors()[key];
      }
      Swal.fire({
        text: displayError,
        icon: "error",
      });
    }

    axios
      .post(`${config.basURL}dashboard/create-news-view`, createNew, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        getAllCourses();
        setCreateNew({ text: "" });
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
   if(userAuth){
    getAllCourses();
    getExtraData()
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-units-page">
        <h1>الأخبار</h1>
        <form className="new-unit mb-4" onSubmit={createNews}>
          <div className="unit-name w-100 d-flex justify-content-center align-items-center">
            <textarea
              name="text"
              className="unit-name-input"
              placeholder="الخبر"
              onChange={addData}
              value={createNew.text}
            />
            <select name="year" id="" onChange={addData} >
              <option value=""> حدد المرحله الدراسيه </option>
              {
                years ? years.map(year => <option value={year.id}> {year.name}  </option>) : <p> جاري التحميل... </p>
              }
            </select>
            <button className="save-data">
              {isLoading ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                "اضافه"
              )}
            </button>
          </div>
        </form>
        {newsData ? (
          newsData?.map((data, idx) => (
            <NewsBox
              data={data}
              idx={idx + 1}
              refresh={getAllCourses}
              openPopup={openPopupFunc}
            />
          ))
        ) : (
          <Placeholder />
        )}
      </div>

      <div
        className={`update-unite-popup ${openPopup ? "open" : ""}`}
        onClick={closePopup}
      >
        {currentNews && (
          <div className="box">
            <input
              type="text"
              placeholder={currentNews.name}
              name="name"
              value={updateData.name ? updateData.name : ""}
              autoComplete="false"
              onChange={updateDataFunc}
            />
            {/* <button onClick={() => sendUpdateData(currentNews.id)}>
              {" "}
              تعديل{" "}
            </button> */}
          </div>
        )}
      </div>
    </>
  );
}
