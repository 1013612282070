import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import ExamsRowBox from "../../../Components/Dashboard/Exams/ExamsRowBox";
import Placeholder from "../../../Components/Used/Placeholder";
import Swal from "sweetalert2";

export default function AllExams() {
  const [allCourses, setallCourses] = useState(false);
  const { userAuth } = useContext(auth);
  const [exam_type, setexam_type] = useState("");
  const [selectedCourse, setselectedCourse] = useState(null);
  const [courseUnits, setcourseUnits] = useState(false);
  const [selectedLesson, setselectedLesson] = useState(false);
  const [exams, setexams] = useState([]);
  const [lesson, setlesson] = useState(null);
  const [isloading, setisloading] = useState(false);

  const updateExamType = (e) => {
    setexam_type(e.target.value);
  };

  const updateCourseUnits = (e) => {
    console.log(e.target.value);
    let units;
    if (e.target.value !== "اختر اسم الكورس") {
      units = allCourses.filter(
        (course) => course.id === Number(e.target.value)
      )[0].course_units;
    } else {
      units = [];
      setselectedLesson([]);
    }
    setcourseUnits(units);
  };

  const updateCourseLessons = (e) => {
    let lesons = courseUnits.filter(
      (unit) => unit.id === Number(e.target.value)
    )[0].unit_lessons;
    setselectedLesson(lesons);
  };

  const getAllCourses = () => {
    axios
      .get(`${config.basURL}dashboard/course-overview`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setallCourses(res.data.courses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExams = (e) => {
    e.preventDefault();
    setisloading(true);
    if (exam_type === "course") {
      if (selectedCourse === null) {
        Swal.fire({
          text: "برجاء اختيار الكورس",
          icon: "error",
        });
        return;
      }
      axios
        .get(
          `${config.basURL}dashboard/course-exam-overview/${selectedCourse}`,
          {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
          }
        )
        .then((res) => {
          setisloading(false);
          setexams(res.data.exam_course);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (exam_type === "lesson") {
      if (lesson === null) {
        Swal.fire({
          text: "برجاء اختيار الدرس",
          icon: "error",
        });
      }
      axios
        .get(`${config.basURL}dashboard/lesson-exam-overview/${lesson}`, {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        })
        .then((res) => {
          setisloading(false);
          setexams(res.data.exam_course);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="dashboard-all-exams-page container-fluid dashboard-exams-page">
        <h1>جميع الامتحانات</h1>
        <form action="" className="exams-search" onSubmit={getExams}>
          <select name="exam_type" id="" onChange={updateExamType}>
            <option> اختر نوع الامتحان </option>
            <option value="lesson"> الدروس </option>
            <option value="course"> الكورسات </option>
          </select>
          {exam_type === "course" && (
            <select
              name="course"
              id=""
              onChange={(e) => setselectedCourse(e.target.value)}
            >
              <option> اختر اسم الكورس </option>
              {allCourses &&
                allCourses.map((course) => (
                  <option value={course.id}> {course.name} </option>
                ))}
            </select>
          )}
          {exam_type === "lesson" && (
            <>
              <select name="course" id="" onChange={updateCourseUnits}>
                <option value={null}> اختر اسم الكورس </option>
                {allCourses &&
                  allCourses.map((course) => (
                    <option value={course.id}> {course.name} </option>
                  ))}
              </select>
              <select name="units" id="" onChange={updateCourseLessons}>
                <option> اختر اسم الوحده </option>
                {courseUnits &&
                  courseUnits.map((unit) => (
                    <option value={unit.id}> {unit.name} </option>
                  ))}
              </select>
              <select
                name="lesson"
                id=""
                onChange={(e) => setlesson(e.target.value)}
              >
                <option> اختر اسم الدرس </option>
                {selectedLesson &&
                  selectedLesson.map((lesson) => (
                    <option value={lesson.id}> {lesson.name} </option>
                  ))}
              </select>
            </>
          )}
          <button>
            {isloading ? (
              <i className="fa-solid fa-circle-notch fa-spin"></i>
            ) : (
              "ابحث"
            )}
          </button>
        </form>
        <div className="content-container">
          {exams ? (
            exams.length ? (
              exams.map((exam) => (
                <ExamsRowBox
                  type={exam_type}
                  data={exam}
                  courseId={selectedCourse}
                />
              ))
            ) : (
              <p className="text-center w-100"> لا يوجد امتحانات </p>
            )
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </>
  );
}
