const SectionContainer = (props) => {
  return (
    <div className="dashboard-section-container">
      <h6>{props.sectionTitle}</h6>
      <div className="content">{props.children}</div>
    </div>
  );
};

export default SectionContainer;
