import React from 'react'

export default function AddUser() {
    return <>
        <div className="container-fluid dashboard-add-user-page">
            <h1>
                اضافة مستخدم
            </h1>
            <form className="add-user">
                <div className="right">
                    <input className='d-none' type="file" name="user-image" id="user-image" />
                    <div className="image">
                        <img src="" alt="" className='w-100 h-100' />
                    </div>
                    <label htmlFor="user-image">
                        <i className="fa-solid fa-cloud-arrow-up"></i>
                        تحميل
                    </label>
                    <button className='remove-image'>
                        <i className="fa-solid fa-x"></i>
                        حذف
                    </button>

                </div>
                <div className="left">
                    <h6>
                        بيانات المستخدم
                    </h6>
                    <div className="inputs">
                        <div>
                            <label htmlFor="first-name">الاسم الاول</label>
                            <input type="text" id='first-name' placeholder='الاسم الاول' />
                        </div>
                        <div>
                            <label htmlFor="last-name"> الاسم الاخير</label>
                            <input type="text" id='last-name' placeholder='الاسم الاخير' />
                        </div>
                        <div>
                            <label htmlFor="email"> البريد الالكتروني</label>
                            <input type="text" id='email' />
                        </div>
                        <div>
                            <label htmlFor="phone">  رقم الهاتف</label>
                            <input type="text" id='phone' />
                        </div>
                        <div>
                            <label htmlFor="userName">  أسم المستخدم</label>
                            <input type="text" id='userName' />
                        </div>
                        <div>
                            <label htmlFor="password">  الرقم السري</label>
                            <input type="password" id='password' />
                        </div>
                        <div>
                            <label htmlFor="confirm-password"> اعادة كتابة الرقم السري</label>
                            <input type="password" id='confirm-password' />
                        </div>
                    </div>
                    <div className="permissions">
                        <h6>
                            الصلاحيات
                        </h6>
                        <ul>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                صلاحية
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                صلاحية
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                صلاحية
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                صلاحية
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                صلاحية
                            </li>
                        </ul>
                    </div>
                    <div className="account-type">
                        <h6>
                            نوع الحساب
                        </h6>
                        <ul>
                            <li>Admin</li>
                            <li>Super Admin</li>
                        </ul>
                    </div>
                    <div className='add-btn'>
                        <button>
                            اضافة المستخدم
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </>
}
