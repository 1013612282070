import React from 'react'

export default function NotificationSection(props) {
    return <>
        <section className='notification-section'>
            <h6 className='notification-date'>
                {props.date}
            </h6>
            {props.children}
        </section>
    </>
}
