import React from 'react'

export default function DashboardAttachmentSection(props) {
    return <>
        <div className="attachment-section">
            <div className="title">
                <h6>{props.catTitle}</h6>
                <span className="separate"></span>
            </div>
            <div className="attach-container">
                {props.children}
            </div>
        </div>
    </>
}
