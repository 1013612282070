import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function CourseContentCollapse({ data }) {
  const [openCollapse, setOpenCollapse] = useState(false);

  let toggleCollapse = () => {
    let copy = openCollapse;
    copy ? (copy = false) : (copy = true);
    setOpenCollapse(copy);
  };

  return (
    <>
      <div className="course-content-collapse col-lg-12">
        <div
          className={`top-bar ${openCollapse ? "open" : ""}`}
          onClick={toggleCollapse}
        >
          <p className="p-0 m-0 fw-bold">{data.name}</p>
          <div className="icon">
            <i
              className={`fa-solid ${
                openCollapse ? "fa-chevron-up" : "fa-chevron-down"
              }`}
            ></i>
          </div>
        </div>
        <div className={`collapsed-data ${openCollapse ? "open" : ""}`}>
          {data.unit_lessons.map((lesson) => (
            <>
              <div className="lesson-data mb-2">
                <div className="right">
                  <i className="fa-solid fa-tv"></i>
                  <p key={lesson.id} className="m-0">
                    {lesson.name}
                  </p>
                </div>
              </div>
              <ul className="lesson-content">
                {lesson.exam_set.map((exam) => (
                  <li className="mb-2" key={exam.id}>
                    <div className="right">
                      <i className="fa-brands fa-angular"></i>
                      <p className="m-0">{exam.name}</p>
                    </div>
                    <ul className="left">
                      <li>
                        <div className="top">
                          <i className="fa-regular fa-clock"></i>
                          مده الامتحان
                        </div>
                        <div className="bottom">
                          {exam.exam_minutes}
                          دقائق
                        </div>
                      </li>
                      <li>
                        <div className="top">
                          <i className="fa-regular fa-circle-question"></i>
                          عدد الاسئله
                        </div>
                        <div className="bottom">
                          {exam.exam_questions_count}
                          سؤال
                        </div>
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
