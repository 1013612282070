import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";

export default function MyCoursesBox({ data, active }) {
  function getHumanDate(d) {
    const date = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }

  return (
    <>
      <div className="my-course-box col-lg-12">
        {/* <div className={`active-badge ${data.active ? 'active' : '' } `}>
            {data.active ? 'مفعل' : 'غير مفعل' }
            </div> */}
        <div className="course-image">
          <img className="w-100 h-100" src={`${data.course.cover}`} alt="" />
        </div>
        <div className="course-title-desc">
          <h6 className="fw-bold">{data.course.name}</h6>
          <p className="m-0 p-0">{data.course.about}</p>
        </div>
        <div className="course-time">
          <div className="time">
            <i className="fa-regular fa-clock"></i>
            <p className="m-0 p-0">{getHumanDate(data.course.created)}</p>
          </div>
        </div>
        <div className="course-price">
          {!data.course.free ? (
            <>
              <span>{data.course.price}</span>جـ
            </>
          ) : (
            "مجاني"
          )}
        </div>
        <div className="to-course">
          {active ? (
            <Link to={`/my-course-preview/${data.course.id}`}>
              الدخول للكورس
            </Link>
          ) : (
            <p className="w-100 text-center"> جاري تفعيل الكورس... </p>
          )}
        </div>
      </div>
    </>
  );
}
