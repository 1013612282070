import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import Validator from "../../../Validator";
import Swal from "sweetalert2";
import CategoryBox from "../../../Components/Dashboard/Categories/CategoryBox";

export default function Categories() {
  const { userAuth } = useContext(auth);
  const [places, setPlaces] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [newPlaceName, setNewPlaceName] = useState({ name: "" });

  const closePopup = (e) => {
    if (e.target.classList.contains("create-new-place-popup")) {
      setOpenPopup(false);
    }
  };

  const getPlaces = () => {
    axios
      .get(`${config.basURL}dashboard/places-overview`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setPlaces(res.data.places);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePlaceNameState = (e) => {
    let old = { ...newPlaceName };
    old[e.target.name] = e.target.value;
    setNewPlaceName(old);
  };

  const validation = () => {
    const val = new Validator();
    val.setKey("name").setValue(newPlaceName.name).required();
    return val;
  };

  const sendNewPlace = () => {
    let validated = validation();

    if (validated.haveError) {
      setOpenPopup(false);
      Swal.fire({
        text: validated.getErrors().name,
        icon: "error",
      });
    } else {
      axios
        .post(`${config.basURL}dashboard/places-overview`, newPlaceName, {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        })
        .then((res) => {
          setPlaces(res.data);
          setOpenPopup(false);
          setNewPlaceName({ name: "" });
          Swal.fire({
            text: "تم الاضافه بنجاح",
            icon: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container dashboard-categories-section">
        <h1>الاقسام</h1>
        <div className="categories-create-new">
          {places ? (
            places.length > 0 ? (
              places.map((place) => (
                <CategoryBox data={place} refresh={getPlaces} key={place.id} />
              ))
            ) : (
              false
            )
          ) : (
            <Placeholder />
          )}

          <div className="new-category" onClick={() => setOpenPopup(true)}>
            <i className="fa-solid fa-plus"></i>
            <p>اضافة قسم</p>
          </div>
        </div>
      </div>

      <div
        className={`create-new-place-popup ${openPopup ? "open" : ""}`}
        onClick={closePopup}
      >
        <div className="add-new-place-box">
          <input
            type="text"
            placeholder="اسم القسم الجديد"
            name="name"
            onChange={updatePlaceNameState}
            autoComplete="false"
          />
          <button onClick={sendNewPlace}> اضافه </button>
        </div>
      </div>
    </>
  );
}
