import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import CourseFileBox from "../../../Components/Dashboard/Course/CourseFileBox";
import Validator from "../../../Validator";

export default function CourseFiles() {
  const controller = useRef(null);

  const { userAuth } = useContext(auth);
  const { course_id, type } = useParams();
  const [allCourseFiles, setAllCourseFiles] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setuploadProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState({
    file: "",
  });

  const updateFile = (e) => {
    let data = { ...uploadedFile };
    data["file"] = e.target.files[0];
    setUploadedFile(data);
  };

  const getAllCourseFiles = () => {
    axios
      .get(`${config.basURL}dashboard/${type}-file-overview/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        console.log(res.data.lesson_files);
        type !== "course"
          ? setAllCourseFiles(res.data.lesson_files)
          : setAllCourseFiles(res.data.course_files);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addFile = (e) => {
    controller.current = new AbortController();
    e.preventDefault();
    setIsUploading(true);
    let validate = new Validator();
    validate.setKey("file").setValue(uploadedFile.file).required();

    if (validate.haveError) {
      Swal.fire({
        text: "برجاء اختيار ملف",
        icon: "error",
      });
      setIsUploading(false);
      return;
    }

    axios
      .post(
        `${config.basURL}dashboard/${type}-file-overview/${course_id}`,
        uploadedFile,
        {
          signal: controller.current.signal,
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setuploadProgress(percent);
          },
        }
      )
      .then((res) => {
        setIsUploading(false);
        setuploadProgress(0);
        getAllCourseFiles();
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        setuploadProgress(0);
        setIsUploading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getAllCourseFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid course-files-page">
        <h1>فايلات</h1>
        <form className="add-files" onSubmit={addFile}>
          <div className="input-group">
            <input
              type="file"
              name="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Upload"
              onChange={updateFile}
              required
            />
          </div>
          {isUploading && (
            <>
              <div className="dashboard-upload-header">
                <p> جاري رفع الملف.... </p>
                <button onClick={() => controller.current.abort()}>
                  الغاء
                </button>
              </div>
              <div className="upload-progress">
                <div className="text">{uploadProgress}%</div>
                <div className="bar">
                  <div style={{ width: `${uploadProgress}%` }}></div>
                </div>
              </div>
            </>
          )}
          <button className="add" disabled={isUploading ? true : false}>
            {isUploading ? (
              <i className="fa-solid fa-circle-notch fa-spin"></i>
            ) : (
              "اضافه"
            )}
          </button>
        </form>
        <div className="files row gap-2">
          {allCourseFiles ? (
            allCourseFiles.length > 0 ? (
              allCourseFiles.map((file) => (
                <CourseFileBox
                  type={type}
                  data={file}
                  course_id={course_id}
                  refresh={getAllCourseFiles}
                />
              ))
            ) : (
              <h5 className="col-12 text-center">
                لا يوجد فايلات في هذا{" "}
                {type !== "course" ? " الدرس " : " الكورس "}{" "}
              </h5>
            )
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </>
  );
}
