import React, { useContext, useEffect, useState } from "react";
import userImage from "../../../assets/default-user-image.png";
import { auth } from "../../../Context/AuthStore";
import axios from "axios";
import config from "../../../config";
import Swal from "sweetalert2";
import Validator from "../../../Validator";

export default function Profile() {
  const { userAuth } = useContext(auth);

  const [loading, setLoading] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [years, setYears] = useState([]);
  const [types, setTypes] = useState([]);
  const [places, setplaces] = useState([]);

  const [studentCode, setStudentCode] = useState("");

  const [studentData, setstudentData] = useState(false);

  const [updateData, setupdateData] = useState({});

  const [errors, seterrors] = useState({});

  const updateUserData = (e) => {
    let updatedData = { ...updateData };
    updatedData[e.target.name] = e.target.value;
    setupdateData(updatedData);
  };

  // get years and type education and places
  const getUserYearEducation = () => {
    axios
      .get(`${config.basURL}student/student-extra`, {
        headers: {
          Authorization: config.ApiKey,
        },
      })
      .then((res) => {
        let response = [...years];
        response = res.data.years;
        setYears(response);

        let typeResponse = [...types];
        typeResponse = res.data.type_education;
        setTypes(typeResponse);

        let placeResponse = [...places];
        typeResponse = res.data.places;
        setplaces(placeResponse);
      })
      .catch((err) => console.log(err));
  };

  // get user data
  const getUserProfileData = () => {
    axios
      .get(`${config.basURL}student/student-profile`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        // student data
        setstudentData(res.data);

        setIsCode(res.data.by_code);
        setStudentCode(res.data.code);

        // set last name value
        // let lastname = res.data.name.split(' ').slice(1).join(' ');
        // setLastName(lastname)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendData = () => {
    setLoading(true);
    let data = { ...updateData };
    let validation = new Validator();

    // check if code student or not
    if (isCode && studentData.parent_phone === null) {
      let requiredInputs = ["name", "username", "parent_phone", "year"];
      // check if all input fill
      requiredInputs.map((key) => {
        if (data[key] !== undefined) {
          validation.setKey(key).setValue(data[key]).required();
          return key;
        } else {
          validation.setKey(key).addError();
          return key;
        }
      });
      // check if have errors or not
      if (validation.haveError) {
        setLoading(false);
        seterrors(validation.getErrors());
        return false;
      }
    }

    // validation phase
    if (Object.keys(data).length === 0) {
      setLoading(false);
      Swal.fire({
        text: "لا يوجد اي تعديل",
        icon: "warning",
      });
      return;
    } else {
      Object.keys(data).map((key) => {
        switch (key) {
          case "name":
            validation
              .setKey("name")
              .setValue(data.name)
              .required()
              .is_arabic()
              .words_count(4);
            break;
          case "username":
            validation
              .setKey("username")
              .setValue(data.username)
              .required()
              .len(11)
              .number()
              .notMatch(data.parent_phone);
            break;
          case "parent_phone":
            validation
              .setKey("parent_phone")
              .setValue(data.parent_phone)
              .required()
              .len(11)
              .number()
              .notMatch(data.username);
            break;
          case "year":
            validation.setKey("year").setValue(data.year).required();
            break;
          default:
            data.year &&
              validation.setKey("year").setValue(data.year).required();
            break;
        }
        return key;
      });

      seterrors(validation.getErrors());

      if (validation.haveError) {
        setLoading(false);
        let errors = validation.getErrors();
        let errorsDisplay = [];
        Object.keys(errors).map((key) => errorsDisplay.push(`${errors[key]}`));

        Swal.fire({
          text: errorsDisplay.join("\n"),
          icon: "error",
        });
        return;
      }
    }

    // sending data phase
    axios
      .put(`${config.basURL}student/student-update`, data, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        getUserProfileData();
        setupdateData({});
        if (res.data.user_errors) {
          let errors = [];
          Object.keys(res.data.user_errors).map((error) => {
            errors.push(res.data.user_errors[error]);
            return error;
          });
          Swal.fire({
            text: errors.join("\n"),
            icon: "error",
          });
        } else {
          Swal.fire({
            text: "تم تعديل البيانات بنجاح",
            icon: "success",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getUserProfileData();
    getUserYearEducation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container profile-section">
        <div className="profile-image-progress">
          <div className="user-image">
            <div className="image">
              <img src={userImage} alt="" className="w-100 h-100" />
            </div>
          </div>
        </div>

        <div className="top row gap-2 py-3">
          <div className="name-email col-lg-6">
            <div className="name mb-3">
              <div className="group">
                <h6>الأسم</h6>
                <div className="inputs">
                  {studentData &&
                    (isCode ? (
                      studentData.name === null ? (
                        <>
                          <input
                            type="text"
                            name="name"
                            onChange={updateUserData}
                          />
                          {/* <input type='text' name='lastName' onChange={updateUserData} /> */}
                        </>
                      ) : (
                        <p> {studentData.name} </p>
                      )
                    ) : (
                      <>
                        <input
                          type="text"
                          name="name"
                          placeholder={studentData.name}
                          onChange={updateUserData}
                        />
                        {/* <input type='text' name='lastName' placeholder={lastName} onChange={updateUserData} /> */}
                      </>
                    ))}
                </div>
                <ul className={errors["name"] ? "" : "d-none"}>
                  {errors["name"] &&
                    Object.values(errors["name"]).map((error, idx) => (
                      <li className="text-danger" key={idx}>
                        {" "}
                        {error}{" "}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="group year-education">
                <div className="selects">
                  <div className="group">
                    <h6>السنه الدراسيه</h6>
                    {studentData &&
                      years &&
                      (isCode ? (
                        studentData.year === null ? (
                          <>
                            <select name="year" onChange={updateUserData}>
                              <option value="اختر السنه الدراسيه">
                                {" "}
                                اختر السنه الدراسيه{" "}
                              </option>
                              {years &&
                                years
                                  .filter((y) => y.name !== "الكل")
                                  .map((year) => (
                                    <option value={year.id} key={year.id}>
                                      {year.name}
                                    </option>
                                  ))}
                            </select>
                          </>
                        ) : (
                          <p> {studentData.year.name} </p>
                        )
                      ) : (
                        <>
                          <select name="year" onChange={updateUserData}>
                            {years &&
                              years
                                .filter((y) => y.name !== "الكل")
                                .map((year) => (
                                  <option
                                    value={year.id}
                                    selected={
                                      year.id === studentData.year.id
                                        ? true
                                        : false
                                    }
                                    key={year.id}
                                  >
                                    {year.name}
                                  </option>
                                ))}
                          </select>
                        </>
                      ))}
                    <ul className={errors["year"] ? "" : "d-none"}>
                      {errors["year"] &&
                        Object.values(errors["year"]).map((error, idx) => (
                          <li className="text-danger" key={idx}>
                            {" "}
                            {error}{" "}
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="group">
                    <h6>التعليم</h6>
                    {studentData &&
                      types &&
                      (isCode ? (
                        studentData.type_education === null ? (
                          <>
                            <select
                              name="type_education"
                              onChange={updateUserData}
                            >
                              {types &&
                                types.map((year) => (
                                  <option value={year.id} key={year.id}>
                                    {year.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        ) : (
                          <p> {studentData.type_education.name} </p>
                        )
                      ) : (
                        <>
                          <select
                            name="type_education"
                            onChange={updateUserData}
                          >
                            {types &&
                              types.map((year) => (
                                <option
                                  value={year.id}
                                  selected={
                                    year.id === studentData.type_education.id
                                      ? true
                                      : false
                                  }
                                  key={year.id}
                                >
                                  {year.name}
                                </option>
                              ))}
                          </select>
                        </>
                      ))}
                  </div>
                  <ul className={errors["type_education"] ? "" : "d-none"}>
                    {errors["type_education"] &&
                      Object.values(errors["type_education"]).map(
                        (error, idx) => (
                          <li className="text-danger" key={idx}>
                            {" "}
                            {error}{" "}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="place mb-4">
              <div className="group">
                <h6>مكان التسجيل</h6>
                {studentData && studentData.place && studentData.place.name}
              </div>
              {isCode && (
                <div className="group">
                  <h6>الكود</h6>
                  <p>{studentCode}</p>
                </div>
              )}
            </div>
            <div className="email">
              <div className="group">
                <h6>البريد الالكتروني</h6>
                <div>
                  {studentData &&
                    (isCode ? (
                      studentData.user.email === "" ? (
                        <>
                          <input
                            onChange={updateUserData}
                            type="email"
                            name="email"
                          />
                        </>
                      ) : (
                        <p> {studentData.user.email} </p>
                      )
                    ) : (
                      <>
                        <input
                          onChange={updateUserData}
                          type="email"
                          name="email"
                          placeholder={studentData.user.email}
                        />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 birthdate-phone">
            <div className="phone">
              <div className="group">
                <h6>رقم الهاتف</h6>
                <div>
                  {studentData &&
                    (isCode ? (
                      studentData.user.username === studentData.code ? (
                        <>
                          <input
                            onChange={updateUserData}
                            type="text"
                            name="username"
                            maxLength={11}
                          />
                        </>
                      ) : (
                        <p> {studentData.user.username} </p>
                      )
                    ) : (
                      <>
                        <input
                          onChange={updateUserData}
                          type="text"
                          name="username"
                          placeholder={studentData.user.username}
                          maxLength={11}
                        />
                      </>
                    ))}
                </div>
                <ul className={errors["username"] ? "" : "d-none"}>
                  {errors["username"] &&
                    Object.values(errors["username"]).map((error, idx) => (
                      <li className="text-danger" key={idx}>
                        {" "}
                        {error}{" "}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="group mt-3">
                <h6>رقم هاتف ولي الامر</h6>
                <div>
                  {studentData &&
                    (isCode ? (
                      studentData.parent_phone === null ? (
                        <>
                          <input
                            onChange={updateUserData}
                            type="text"
                            name="parent_phone"
                            maxLength={11}
                          />
                        </>
                      ) : (
                        <p> {studentData.parent_phone} </p>
                      )
                    ) : (
                      <>
                        <input
                          onChange={updateUserData}
                          type="text"
                          name="parent_phone"
                          placeholder={studentData.parent_phone}
                          maxLength={11}
                        />
                      </>
                    ))}
                </div>
                <ul className={errors["parent_phone"] ? "" : "d-none"}>
                  {errors["parent_phone"] &&
                    Object.values(errors["parent_phone"]).map((error, idx) => (
                      <li className="text-danger" key={idx}>
                        {" "}
                        {error}{" "}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="save-data py-3">
          <button onClick={sendData}>
            {loading ? (
              <i className="fa-solid fa-circle-notch fa-spin"></i>
            ) : (
              "حفظ البيانات"
            )}
          </button>
        </div>
      </div>
    </>
  );
}
