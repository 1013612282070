import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();

  return (
    <aside className="dashboard-sidebar hidden-sm">
      <ul className="links ">
        <li className={location.pathname === "/dashboard" ? "active" : ""}>
          <Link to={"/dashboard"}>
            <i className="fa-solid fa-house"></i>
            <p className="m-0 p-0">الرئيسي</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/students" ? "active" : ""
          }
        >
          <Link to={"/dashboard/students"}>
            <i className="fa-solid fa-users"></i>
            <p className="m-0 p-0">الطلاب</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/categories" ? "active" : ""
          }
        >
          <Link to={"/dashboard/categories"}>
            <i className="fa-solid fa-layer-group"></i>
            <p className="m-0 p-0">الأقسام</p>
          </Link>
        </li>
        <li
          className={location.pathname === "/dashboard/courses" ? "active" : ""}
        >
          <Link to={"/dashboard/courses"}>
            <i className="fa-solid fa-table"></i>
            <p className="m-0 p-0">الكورسات</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/course-subscriptions"
              ? "active"
              : ""
          }
        >
          <Link to={"/dashboard/course-subscriptions"}>
            <i className="fa-solid fa-desktop"></i>
            <p className="m-0 p-0">تفعيل الكورسات</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/all-exams" ? "active" : ""
          }
        >
          <Link to={"/dashboard/all-exams"}>
            <i className="fa-regular fa-circle-question"></i>
            <p className="m-0 p-0">الامتحانات</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/lesson-views" ? "active" : ""
          }
        >
          <Link to={"/dashboard/lesson-views"}>
            <i className="fa-solid fa-play"></i>
            <p className="m-0 p-0">المشاهدات</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/subscriptions" ? "active" : ""
          }
        >
          <Link to={"/dashboard/subscriptions"}>
            <i className="fa-solid fa-user-plus"></i>
            <p className="m-0 p-0">الاشتراكات والفواتير</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/create-copon" ? "active" : ""
          }
        >
          <Link to={"/dashboard/create-copon"}>
            <i className="fa-solid fa-ticket-simple"></i>
            <p className="m-0 p-0">الأكواد</p>
          </Link>
        </li>
        <li
          className={location.pathname === "/dashboard/users" ? "active" : ""}
        >
          <Link to={"/dashboard/users"}>
            <i className="fa-solid fa-person"></i>
            <p className="m-0 p-0">المستخدمين</p>
          </Link>
        </li>
        <li className={location.pathname === "/dashboard/news" ? "active" : ""}>
          <Link to={"/dashboard/news"}>
            <i className="fa-solid fa-newspaper"></i>
            <p className="m-0 p-0">الأخبار</p>
          </Link>
        </li>
        <li
          className={
            location.pathname === "/dashboard/notifications" ? "active" : ""
          }
        >
          <Link to={"/dashboard/notifications"}>
            <i className="fa-solid fa-bell"></i>
            <p className="m-0 p-0">التنبيهات (الاشعارات)</p>
          </Link>
        </li>
        {/* <li className={location.pathname === '/dashboard/attachments' ? 'active' : ''}>
                    <Link to={'/dashboard/attachments'}>
                        <i className="fa-solid fa-book-bookmark"></i>
                        <p className='m-0 p-0'>
                            المذكرات
                        </p>
                    </Link>
                </li> */}
      </ul>
    </aside>
  );
}
