import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar({ year }) {
  let location = useLocation();

  return (
    <>
      <aside className="user-profile-sidebar">
        <h5 className="fw-bold pb-4">الملف الشخصي</h5>
        <ul className="profile-links">
          <li
            className={`${
              location.pathname === "/user-profile" ? "active" : ""
            }`}
          >
            <Link to={"/user-profile"}>
              <i className="fa-regular fa-user"></i>
              ملف المستخدم
            </Link>
          </li>
          <li className={``}>
            <Link to={`/student-all-courses`}>
              <i className="fa-solid fa-book-bookmark"></i>
              جميع الكورسات
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/user-profile/my-courses" ? "active" : ""
            }`}
          >
            <Link to={"my-courses"}>
              <i className="fa-solid fa-table-cells-large"></i>
              كورساتي
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/user-profile/security-and-login"
                ? "active"
                : ""
            }`}
          >
            <Link to={"/user-profile/security-and-login"}>
              <i className="fa-solid fa-shield"></i>
              الأمان و تاريخ تسجيل الدخول
            </Link>
          </li>
          {/* <li>
                    <Link to={'/user-profile'}>
                        <i className="fa-regular fa-file-excel"></i>
                        الفواتير
                    </Link>
                </li> */}
          <li
            className={`${
              location.pathname === "/user-profile/course-subscreptions"
                ? "active"
                : ""
            }`}
          >
            <Link to={"/user-profile/course-subscreptions"}>
              <i className="fa-solid fa-circle-plus"></i>
              الاشتراكات
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/user-profile/my-exam-score"
                ? "active"
                : ""
            }`}
          >
            <Link to={"/user-profile/my-exam-score"}>
              <i className="fa-regular fa-circle-check"></i>
              نتائج الامتحانات
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/user-profile/my-homework-score"
                ? "active"
                : ""
            }`}
          >
            <Link to={"/user-profile/my-homework-score"}>
              <i className="fa-regular fa-clipboard"></i>
              نتائج الواجب
            </Link>
          </li>
          <li
            className={`${
              location.pathname === "/user-profile/news" ? "active" : ""
            }`}
          >
            <Link to={"/user-profile/news"}>
              <i className="fa-solid fa-newspaper"></i>
              أخر الأخبار
            </Link>
          </li>
          {/* <li>
                    <Link to={'/user-profile'}>
                        <i className="fa-solid fa-display"></i>
                        نتائج كويز الفيديو
                    </Link>
                </li> */}
          {/* <li>
                    <Link to={'/user-profile/notification'}>
                        <i className="fa-regular fa-bell"></i>
                        الاشعارات
                    </Link>
                </li> */}
          {/* <li className={`${location.pathname === '/user-profile/level-indicator' ? 'active' : ''}`}>
                    <Link to={'/user-profile/level-indicator'}>
                        <i className="fa-solid fa-arrow-trend-up"></i>
                        مؤشر المستوي
                    </Link>
                </li> */}
        </ul>
      </aside>
    </>
  );
}
