import React, { useContext, useEffect, useState } from "react";
import DashboardCourseBox from "../../../Components/Dashboard/Course/DashboardCourseBox";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import Swal from "sweetalert2";

export default function DashboardCourses() {
  const { userAuth } = useContext(auth);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [year, setYear] = useState([]);
  const [places, setPlaces] = useState([]);
  const [status, setStatus] = useState("");

  const [updatedData, setUpdatedData] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [currentUpdateCourse, setCurrentUpdateCourse] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isUpdating, setIsUpdating] = useState(false);

  const togglePopup = (data) => {
    setCurrentUpdateCourse({
      ...data,
      pending: data.pending === true ? "Active" : "Pending",
    });
    openPopup ? setOpenPopup(false) : setOpenPopup(true);
    setUpdatedData({});
  };
  const closePopup = (e) => {
    if (e.target.classList.contains("update-course-popup")) {
      setOpenPopup(false);
      setCurrentUpdateCourse(false);
    }
  };

  const addUpdateData = (e) => {
    let data = { ...updatedData };
    if (e.target.name === "cover") {
      data["cover"] = e.target.files[0];
    } else {
      data[e.target.name] = e.target.value;
    }
    setUpdatedData(data);
  };

  const [newCourseData, setNewCourseData] = useState({
    name: "",
    about: "",
    cover: "",
    price: "",
    year: "",
    place: "",
    free: "",
    pending: "",
  });

  const update = (e) => {
    setNewCourseData({ ...newCourseData, [e.target.name]: e.target.value });
  };

  const clickUpdate = (e) => {
    let elements = e.target.parentElement.children;
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove("active");
    }
    e.target.classList.add("active");
    setNewCourseData({
      ...newCourseData,
      [e.target.getAttribute("data-name")]: Number(
        e.target.getAttribute("data-id")
      ),
    });
  };

  const updateImageData = (e) => {
    setNewCourseData({ ...newCourseData, cover: e.target.files[0] });
  };

  const getCourses = (place) => {
    setIsLoading(true);
    axios
      .get(`${config.basURL}dashboard/course-overview?place_id=${place}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setCourses(res.data.courses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getExtra = () => {
    axios
      .get(`${config.basURL}student/student-extra`, {
        headers: {
          Authorization: config.ApiKey,
          auth: userAuth,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setYear(res.data.years);
        setPlaces(res.data.places);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const send = (e) => {
    e.preventDefault();
    setIsSending(true);
    let data = { ...newCourseData };
    data.price === 0 ? (data.free = true) : (data.free = false);
    axios
      .post(`${config.basURL}dashboard/course-overview`, data, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setIsSending(false);
        getCourses();
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        }).then(() => {
          setNewCourseData({
            name: "",
            about: "",
            cover: "",
            price: "",
            year: "",
            place: "",
            free: "",
            pending: "",
          });
          // Remove active class from data-name = year, data-name = place and data-name = pending
          let elements = document.querySelectorAll("[data-name]");
          for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("active");
          }
          setStatus("");
          document.getElementById("course-image-input").value = "";
        });
      })
      .catch((err) => {
        setIsSending(false);
        console.log(err);
      });
  };

  const deleteCourse = (id) => {
    Swal.fire({
      title: `هل انت متاكد من حذف الكورس`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "لا شكرا",
      showConfirmButton: true,
      confirmButtonText: "حذف",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        axios
          .delete(`${config.basURL}dashboard/course-overview`, {
            headers: {
              Authorization: config.ApiKey,
              auth: `Bearer ${userAuth}`,
            },
            data: {
              id_course: id,
            },
          })
          .then((res) => {
            getCourses();
            Swal.fire({
              text: "تم الحذف بنجاح",
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const sendUpdateData = (id) => {
    if (Object.keys(updatedData).length === 0) {
      setOpenPopup(false);
      Swal.fire({
        text: "لا يوجد تعديلات",
        icon: "warning",
      });
      return false;
    }
    axios
      .put(`${config.basURL}dashboard/course-update/${id}`, updatedData, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setOpenPopup(false);
        getCourses();
        Swal.fire({
          text: "تم التعديل بنجاح",
          icon: "success",
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // getCourses();
    getExtra();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-courses">
        <h1>الكورسات</h1>
        <form
          className="add-new-course"
          onSubmit={send}
          encType="multipart/form-data"
        >
          <input
            type="file"
            name="cover"
            id="course-image-input"
            className="d-none"
            onChange={updateImageData}
          />
          <label className="course-image d-block" htmlFor="course-image-input">
            <p>تحميل الصورة</p>
            {newCourseData.cover && (
              <img
                src={URL.createObjectURL(newCourseData.cover)}
                alt=""
                className="w-100 h-100"
              />
            )}
          </label>

          <div className="course-title-desc">
            <input
              type="text"
              className="course-title"
              name="name"
              placeholder="عناون الكورس"
              onChange={update}
              value={newCourseData.name}
            />
            <textarea
              name="about"
              id=""
              className="course-desc"
              placeholder="وصف الكورس"
              onChange={update}
              value={newCourseData.about}
            ></textarea>
          </div>

          <div className="school-year">
            <h6>السنة الدراسية</h6>
            <ul>
              {year &&
                year.map((y) => (
                  <li
                    key={y.id}
                    data-name="year"
                    data-id={y.id}
                    onClick={clickUpdate}
                  >
                    {" "}
                    {y.name}{" "}
                  </li>
                ))}
            </ul>
          </div>

          <div className="category-type">
            <h6>نوع القسم</h6>
            <ul>
              {places &&
                places.map((place) => (
                  <li
                    key={place.id}
                    data-name="place"
                    data-id={place.id}
                    onClick={clickUpdate}
                  >
                    {" "}
                    {place.name}{" "}
                  </li>
                ))}
            </ul>
          </div>

          <div className="category-type">
            <h6>حالة الكورس</h6>
            <ul>
              <li
                className={`${status === true ? "active" : ""}`}
                data-name="pending"
                data-id={"Pending"}
                onClick={() => {
                  setNewCourseData({
                    ...newCourseData,
                    pending: true,
                  });
                  setStatus(true);
                }}
              >
                مفعل
              </li>
              <li
                className={`${status === false ? "active" : ""}`}
                data-name="pending"
                data-id={"Active"}
                onClick={() => {
                  setNewCourseData({
                    ...newCourseData,
                    pending: false,
                  });
                  setStatus(false);
                }}
              >
                غير مفعل
              </li>
            </ul>
          </div>

          <div className="publish-date">
            {/* <h6>
                        تاريخ النشر
                    </h6>
                    <input type="date" name="publish-date" id="" className='publish-date' /> */}
            <input
              type="text"
              name="price"
              className="course-price"
              placeholder="أدخل سعر الكورس"
              onChange={update}
              value={newCourseData.price}
            />
            <button>
              {isSending ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                "حفظ بيانات الكورس"
              )}
            </button>
          </div>
        </form>
        <div className="d-flex align-items-center justify-content-center p-4">
            <button onClick={() => getCourses(2)}> online </button>
            <button onClick={() => getCourses(1)}> center </button>
        </div>
        <div className="courses-rows">
          {isLoading ? (
            <Placeholder />
          ) : courses.length > 0 ? (
            courses.map((course) => (
              <DashboardCourseBox
                updateCourse={togglePopup}
                deleteCourse={deleteCourse}
                key={course.id}
                data={course}
              />
            ))
          ) : (
            <p className="text-center">لا يوجد كورسات</p>
          )}
        </div>
      </div>

      {/* update popup */}

      <div
        className={`update-course-popup ${openPopup ? "open" : ""}`}
        onClick={closePopup}
      >
        <div className="box">
          {currentUpdateCourse && (
            <div>
              <div className="image">
                <input
                  type="file"
                  name="cover"
                  id="cover"
                  className="d-none"
                  onChange={addUpdateData}
                />
                <img
                  src={
                    updatedData.cover
                      ? URL.createObjectURL(updatedData.cover)
                      : currentUpdateCourse.cover
                  }
                  alt=""
                  className="w-100 h-100"
                />
                <label htmlFor="cover"> تغيير الصوره </label>
              </div>
              <div className="name-price">
                <input
                  type="text"
                  name="name"
                  onChange={addUpdateData}
                  placeholder={currentUpdateCourse.name}
                />
                <input
                  type="text"
                  name="price"
                  onChange={addUpdateData}
                  placeholder={currentUpdateCourse.price}
                />
              </div>
              <div className="year-place">
                <select name="year" id="" onChange={addUpdateData}>
                  {year &&
                    year.map((y) => (
                      <option
                        value={y.id}
                        selected={
                          currentUpdateCourse.year.id === y.id ? true : false
                        }
                      >
                        {" "}
                        {y.name}{" "}
                      </option>
                    ))}
                </select>
                <select name="year" id="" onChange={addUpdateData}>
                  {places &&
                    places.map((place) => (
                      <option
                        value={place.id}
                        selected={
                          currentUpdateCourse.place.id === place.id
                            ? true
                            : false
                        }
                      >
                        {" "}
                        {place.name}{" "}
                      </option>
                    ))}
                </select>
              </div>
              <div className="year-place pending">
                <select
                  name="pending"
                  className="w-100"
                  onChange={(e) => {
                    setUpdatedData({
                      ...updatedData,
                      pending: e.target.value === "true" ? true : false,
                    });
                  }}
                >
                  <option
                    value={false}
                    selected={
                      currentUpdateCourse.pending === "Pending" ? true : false
                    }
                  >
                    مفعل
                  </option>
                  <option
                    value={true}
                    selected={
                      currentUpdateCourse.pending === "Active" ? true : false
                    }
                  >
                    غير مفعل
                  </option>
                </select>
              </div>
              <textarea
                name="about"
                id=""
                onChange={addUpdateData}
                placeholder={currentUpdateCourse.about}
              ></textarea>
              <button
                className="update-course"
                onClick={() => sendUpdateData(currentUpdateCourse.id)}
              >
                {isUpdating ? (
                  <i className="fa-solid fa-circle-notch fa-spin"></i>
                ) : (
                  "تعديل"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
