import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Context/AuthStore";
import useQueryParams from "../Hooks/useQueryParams";
import axios from "axios";
import config from "../config";
import { Link, useParams } from "react-router-dom";
import Placeholder from "../Components/Used/Placeholder";

export default function HaveQuiz(props) {
  const { userAuth } = useContext(auth);
  let { lesson } = useQueryParams();
  const { course_id } = useParams();
  const [mandatory, setmandatory] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [quiz, setquiz] = useState(false);

  const getLessonData = () => {
    axios
      .get(
        `${config.basURL}subscription/access-lesson/${course_id}/${lesson}`,
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data)
        if (res.data.quiz !== undefined) {
          // console.log(res.data)
          setmandatory(true);
          setquiz(res.data.quiz);
          setisLoading(false);
        } else {
          setmandatory(false);
          setisLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLessonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth, lesson]);

  return (
    <>
      {isLoading ? (
        <div className="subscription-gourd quiz-before-lesson">
          <Placeholder />
        </div>
      ) : mandatory ? (
        <div className="subscription-gourd quiz-before-lesson">
          <div className="quiz-details">
            <h5>يجب اجتياز الاختبار اولا</h5>
            <p className="m-0 text-center"> {quiz.name} </p>
            <div className="details">
              <div>
                <i className="fa-regular fa-circle-question"></i>
                {quiz.quiz_questions_count} سؤال
              </div>
              <div>
                <i className="fa-regular fa-clock"></i>
                {quiz.time} دقيقه
              </div>
              <div>
                <i className="fa-regular fa-circle-check"></i>
                {quiz.success_rate} درجه النجاح
              </div>
            </div>
            <Link to={`/quiz-before-lesson/${course_id}/${quiz.id}`}>
              الذهاب الي الاختبار
            </Link>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
}
