import React from "react";

const NewsBoxStudents = ({ data }) => {
  const getHumanDate = (d) => {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  };

  return (
    <div className="unit-course-box news-course-box w-100"
    style={{
      maxWidth: "100%",
      padding: "10px",
      overflowY: "auto",
    }}
    >
      <p className="title border-0">{data.text}</p>
      <div className="date-setting">
        <div className="date">
          <p className="m-0">التاريخ</p>
          <p className="m-0">{getHumanDate(data.created)}</p>
        </div>
      </div>
    </div>
  );
};

export default NewsBoxStudents;
