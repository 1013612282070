import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placholder from "../../../Components/Used/Placeholder";
import Swal from "sweetalert2";

export default function ExamsScore() {
  const [examsScore, setExamsScore] = useState(false);
  const { userAuth } = useContext(auth);
  const navigate = useNavigate();

  const showExamAnswers = (exam_id, exam_name, result_date) => {
    let date = new Date();
    let rDate = new Date(result_date);

    if (result_date !== undefined) {
      if (
        date.getFullYear() >= rDate.getFullYear() &&
        date.getMonth() >= rDate.getMonth() &&
        date.getDate() >= rDate.getDate()
      ) {
        navigate(`/user-profile/show-exam-answers/${exam_id}/${exam_name}`);
      } else {
        Swal.fire({
          text: `سيتم عرض اجابات الامتحان يوم ${getHumanDate(result_date)}`,
          icon: "info",
        });
      }
    } else {
      navigate(`/user-profile/show-exam-answers/${exam_id}/${exam_name}`);
    }
  };

  const getExamsScore = () => {
    axios
      .get(`${config.basURL}exam/exam-results`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        let data = res.data.filter((exam) => {
          return exam.exam !== null;
        });
        setExamsScore(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getHumanDate(d) {
    const date = new Date(d);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("ar", options);
  }

  useEffect(() => {
    getExamsScore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container exam-score-section">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">التسلسل</th>
              <th scope="col">أسم الامتحان</th>
              <th scope="col">عدد الاسئلة</th>
              <th scope="col">درجه الامتحان</th>
              <th scope="col">النتيجة</th>
              <th scope="col">عدد الاسئلة المحلولة</th>
              <th scope="col">الاجابات</th>
              <th scope="col">اعاده الامتحان</th>
              <th scope="col">الوقت المستغرق</th>
              <th scope="col">وقت بدأ الامتحان</th>
            </tr>
          </thead>
          <tbody>
            {examsScore ? (
              examsScore.map((exam) => (
                <tr>
                  <td>{exam.id}</td>
                  <td>{exam.exam.name}</td>
                  <td>{exam.exam.exam_questions_count}</td>
                  <td> {exam.exam.exam_degree} </td>
                  <td>
                    {Math.floor((exam.degree / exam.exam.exam_degree) * 100)} %
                  </td>
                  <td className="score-bar">
                    <span>{exam.question_answer.length}</span>
                    <div className="bar">
                      <span
                        style={{
                          width: `${Math.floor(
                            (exam.question_answer.length /
                              exam.exam.exam_questions_count) *
                              100
                          )}%`,
                        }}
                      ></span>
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        showExamAnswers(
                          exam.id,
                          exam.exam.name,
                          exam.exam.result_date
                        )
                      }
                    >
                      عرض الاجابات
                    </button>
                  </td>
                  <td>
                    {exam.exam.is_many_times ? (
                      <Link
                        className="retake"
                        to={`/quiz/${exam.exam.course}/${exam.exam.id}`}
                      >
                        {" "}
                        اعاده الامتحان{" "}
                      </Link>
                    ) : (
                      <p className="bg-danger text-white">
                        {" "}
                        الامتحان لمره واحده{" "}
                      </p>
                    )}
                  </td>
                  <td className="score-bar">{exam.time_taken} دقيقه</td>
                  <td>{getHumanDate(exam.created)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>
                  <Placholder />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
