import React, { useContext, useEffect, useState } from "react";
import { auth } from "../Context/AuthStore";
import axios from "axios";
import config from "../config";
import CoursesSection from "./../Components/Courses/CoursesSection";

export default function StudentAllCourses() {
  const { userAuth } = useContext(auth);

  const [courses, setcourses] = useState(false);
  const [response, setResponse] = useState(false);

  const getCourses = () => {
    axios
      .get(`${config.basURL}course/student-course-list`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setResponse(true);
        setcourses(res.data.courses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCourses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="courses-title student-all-courses">
        <div className="content">
          <h5>جميع الكورسات</h5>
        </div>
      </div>
      <div className="courses-search-bar py-5 mb-4">
        <div className="container">
          <div className="search-box">
            <input
              type="search"
              name="search"
              placeholder="أبحث بأسم الكورس او المادة"
            />
            <div className="search-btn" role="button">
              <i className="fas fa-search"></i>
            </div>
          </div>
        </div>
      </div>
      <CoursesSection data={courses} response={response} />
    </>
  );
}
