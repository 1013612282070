import React, { useContext, useEffect, useState } from 'react'
import { auth } from './../Context/AuthStore';
import { Navigate } from 'react-router-dom';
import Placeholder from '../Components/Used/Placeholder';


export default function AuthGourd(props) {
    let {userAuth} = useContext(auth)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
      setLoading(false)
      return () => false;
    }, [])
  return <>
    {

      loading ? <div className='loading-gurad'><Placeholder /> </div>: 
        (userAuth ? 
        props.children
        :
        <Navigate to={'/auth'} />)
    }
  </>
}
