import React from "react";
import CourseBox from "./CourseBox";
import Placeholder from "../Used/Placeholder";

export default function CoursesSection(props) {
  return (
    <>
      <div className="container py-3 pb-5 courses-section pb-5">
        {/* <h5 className='section-title fw-bold py-3'>
        كورسات مادة ............
      </h5> */}
        <div className="d-flex justify-content-lg-between justify-content-center align-items-center gap-3">
          {props.response ? (
            props.data.length > 0 ? (
              props.data.map((course) => (
                <CourseBox
                  key={course.id}
                  data={course}
                />
              ))
            ) : (
              <h5 className="text-center">لا يوجد كورسات في هذا القسم</h5>
            )
          ) : (
            <>
              <Placeholder />
            </>
          )}
        </div>
      </div>
    </>
  );
}
