import React, { useContext, useEffect, useState } from "react";
import SectionContainer from "../../../Components/Dashboard/Used/SectionContainer";
import UnitCourseBox from "../../../Components/Dashboard/Unit/UnitCourseBox";
import axios from "axios";
import config from "../../../config";
import { auth } from "../../../Context/AuthStore";
import Placeholder from "../../../Components/Used/Placeholder";
import { useParams } from "react-router-dom";
import Validator from "../../../Validator";
import Swal from "sweetalert2";

export default function Units() {
  const { userAuth } = useContext(auth);
  const { course_id } = useParams();
  const [courseData, setCourseData] = useState(false);
  const [createUnite, setCreateUnite] = useState({ name: "" });
  const [openPopup, setOpenPopup] = useState(false);
  const [currentUnit, setCurrentUnit] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [updateData, setUpdateData] = useState({});

  const addData = (e) => {
    let data = { ...createUnite };
    data[e.target.name] = e.target.value;
    setCreateUnite(data);
  };

  const updateDataFunc = (e) => {
    let data = { ...updateData };
    data[e.target.name] = e.target.value;
    setUpdateData(data);
  };

  const validation = () => {
    let validate = new Validator();
    validate.setKey("name").setValue(createUnite.name).required();
    return validate;
  };

  const closePopup = (e) => {
    if (e.target.classList.contains("update-unite-popup")) {
      setOpenPopup(false);
    }
  };

  const openPopupFunc = (data) => {
    setOpenPopup(true);
    setCurrentUnit(data);
  };

  const getAllCourses = () => {
    axios
      .get(`${config.basURL}dashboard/unit-overview/${course_id}`, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        setCourseData(res.data.course);
      })
      .catch((err) => console.log(err));
  };

  const createUnit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let valid = validation();
    let displayError;
    if (valid.haveError) {
      for (let key in valid.getErrors()) {
        displayError += valid.getErrors()[key];
      }
      Swal.fire({
        text: displayError,
        icon: "error",
      });
      return;
    }

    axios
      .post(
        `${config.basURL}dashboard/unit-overview/${course_id}`,
        createUnite,
        {
          headers: {
            Authorization: config.ApiKey,
            auth: `Bearer ${userAuth}`,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        getAllCourses();
        Swal.fire({
          text: "تم الاضافه بنجاح",
          icon: "success",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const sendUpdateData = (id) => {
    if (Object.keys(updateData).length === 0) {
      setOpenPopup(false);
      Swal.fire({
        text: "لا يوجد اي تعديل",
        icon: "error",
      });
      return;
    }

    axios
      .put(`${config.basURL}dashboard/unit-update/${id}`, updateData, {
        headers: {
          Authorization: config.ApiKey,
          auth: `Bearer ${userAuth}`,
        },
      })
      .then((res) => {
        getAllCourses();
        setUpdateData({});
        setOpenPopup(false);
        Swal.fire({
          text: "تم التعديل بنجاح",
          icon: "success",
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuth]);

  return (
    <>
      <div className="container-fluid dashboard-units-page">
        <h1>الوحدات</h1>
        <form className="new-unit" onSubmit={createUnit}>
          <div className="unit-name">
            <input
              type="text"
              name="name"
              className="unit-name-input"
              placeholder="أسم الوحدة"
              onChange={addData}
            />
            <button className="save-data">
              {isLoading ? (
                <i className="fa-solid fa-circle-notch fa-spin"></i>
              ) : (
                "اضافه"
              )}
            </button>
          </div>
        </form>
        {courseData ? (
          <SectionContainer sectionTitle={courseData.name}>
            {courseData.course_units.map((unit) => (
              <UnitCourseBox
                data={unit}
                refresh={getAllCourses}
                courseid={course_id}
                openPopup={openPopupFunc}
              />
            ))}
          </SectionContainer>
        ) : (
          <Placeholder />
        )}
      </div>

      <div
        className={`update-unite-popup ${openPopup ? "open" : ""}`}
        onClick={closePopup}
      >
        {currentUnit && (
          <div className="box">
            <input
              type="text"
              placeholder={currentUnit.name}
              name="name"
              value={updateData.name ? updateData.name : ""}
              autoComplete="false"
              onChange={updateDataFunc}
            />
            <button onClick={() => sendUpdateData(currentUnit.id)}>
              {" "}
              تعديل{" "}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
