import React from 'react'
import DashboardAttachmentSection from '../../../Components/Dashboard/Attachment/DashboardAttachmentSection'

export default function Attachments() {
    return <>
        <div className="container-fluid dashboard-attachments-page">
            <h1>
                المذكرات
            </h1>
            <DashboardAttachmentSection catTitle={'أسم القسم'}>
                <div className="attachment-box">
                    <div className="image">
                        <img src="" alt="" className='w-100 h-100' />
                    </div>
                    <div className="data">
                        <h6 className="title">
                            عنوان المزكرة
                        </h6>
                        <p className="content m-0">
                            لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة
                            المتمثلة في إدانة السرور ومدح الألم ، وسأقدم لك وصفًا
                            كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم
                            للحقيقة ، الباني البارع
                        </p>
                        <p className="price m-0">
                            350 جنيه
                        </p>
                    </div>
                    <div className="settings">
                        <button className='edit'><i className="fa-solid fa-pen"></i></button>
                        <button className='delete'><i className="fa-solid fa-trash-can"></i></button>
                    </div>
                </div>
            </DashboardAttachmentSection>
        </div>
    </>
}
